import * as CmdQueue from 'BaxterScript/helper/queue/CmdQueue';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import ConsentV2 from 'BaxterScript/version/web/feature/ConsentV2';
import StyleV2 from 'BaxterScript/version/web/feature/StyleV2';
import TimerRefreshV2 from 'BaxterScript/version/web/feature/TimerRefreshV2';
import * as Container from 'BaxterScript/version/web/core/Container';
import BreakpointRefreshV2 from 'BaxterScript/version/web/feature/BreakpointRefreshV2';
import * as InterstitialV2 from 'BaxterScript/version/web/feature/InterstitialV2';
import { State as StateType } from 'BaxterScript/types/State';
import * as State from './core/State';
import * as ProviderV2 from './core/ProviderV2';
import * as LifeCycleV2 from './core/LifecycleV2';
import * as Polyfill from './core/Polyfill';
import * as Preview from './core/Preview';
import AdBlock from './feature/AdBlock';
import Cxense from './feature/script/Cxense';
import Scripts from './feature/Scripts';
import Gemius from './feature/script/Gemius';
import Sati from './feature/script/Sati';

globalThis.Baxter = globalThis.Baxter || {};
globalThis.Baxter.queue = globalThis.Baxter.queue || [];

const trackingScriptsBootstrap = () => {
  if (Cxense) Cxense.bootstrap();
  if (Sati) Sati.bootstrap();
  if (Gemius) Gemius.bootstrap();
};

const initializeV2 = async () => {
  Polyfill.bootstrap();
  if (StyleV2) StyleV2.bootstrap();
  if (ConsentV2) {
    ConsentV2.bootstrap();
    ConsentV2.setDelayedTrackingScripts(trackingScriptsBootstrap);
  } else {
    trackingScriptsBootstrap();
  }
  if (AdBlock) AdBlock.bootstrap();
  if (Scripts) Scripts.bootstrap();
  if (TimerRefreshV2) TimerRefreshV2.bootstrap(Container.setSlot);
  InterstitialV2?.bootstrap?.();
  State.bootstrap();
  if (BreakpointRefreshV2) BreakpointRefreshV2.bootstrap(Container.setSlot);
  ProviderV2.bootstrap();
  await CmdQueue.init(globalThis.Baxter.queue);
};

/* eslint-disable no-undef */
if (!globalThis.Baxter.initialized || Preview.shouldInitialize()) {
  globalThis.Baxter.initialized = true;
  globalThis.Baxter.config = __CONFIG__;
  // todo: merge into config before runtime
  globalThis.Baxter.config.accountId = __ACCOUNT_ID__;
  globalThis.Baxter.config.appId = __APP_ID__;
  globalThis.Baxter.config.versionId = __VERSION_ID__;
  globalThis.Baxter.config.commitHash = __COMMIT_HASH__;
  globalThis.Baxter.config.environment = __ENVIRONMENT__;
  globalThis.Baxter.config.cdnDomain = __CDN_DOMAIN__;
  globalThis.Baxter.config.apiDomain = __API_DOMAIN__;
  // todo: set in 'baxter-native' provider config (not env var)??
  globalThis.Baxter.config.adsDomain = __ADS_DOMAIN__;

  globalThis.Baxter.state = {
    providers: [],
    page: {
      id: undefined,
      params: {},
    },
    placeholders: {},
    containers: {},
    interstitialsV2: { actions: [], slots: {} },
    user: {
      cxense: {},
      sati: {
        init: false,
      },
      active: true,
      consentResolved: false,
      consentGiven: false,
    },
    app: {
      breakpoints: [],
    },
  } as StateType;

  newRelicMetrics.reportMetric(NewRelicMetric.SCRIPT_INITIALIZED);
  console.debug('[SLOTS][INITJS][V2ENABLED]');
  globalThis.Baxter.consent = ConsentV2
    ? ConsentV2.setUserConsent
    : async () => {
        console.debug('[SLOTS][CONSENT][SETUSERCONSENT] disabled in slots so do nothing');
      };
  globalThis.Baxter.page = LifeCycleV2.onPageChanged;
  globalThis.Baxter.set = LifeCycleV2.onSet;
  globalThis.Baxter.clear = LifeCycleV2.onClear;
  globalThis.Baxter.track = LifeCycleV2.onPageTrack;
  globalThis.Baxter.setAfterLoaded = LifeCycleV2.onSetAfterLoaded;
  globalThis.Baxter.setPageParams = LifeCycleV2.onSetPageParams;

  (async () => {
    try {
      console.info('[SLOTS][INITJS] INIT SCRIPT LOADED', globalThis.Baxter.config.versionId);
      if (await Preview.init()) {
        console.info('[SLOTS][INITJS] PREVIEW SCRIPT LOADED', globalThis.Baxter.config.versionId);
      } else if (globalThis.Baxter.config.app.enabled) {
        console.info('[SLOTS][INITJS] INITIALIZING', globalThis.Baxter.config);
        await initializeV2();
      }
    } catch (e) {
      console.error('[SLOTS][INITJS]', e);
      newRelicMetrics.reportError(NewRelicError.SCRIPT_ERROR, { message: (e as Error).message });
    }
  })();
}
