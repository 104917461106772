import { Config } from 'BaxterScript/types/Config';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Callbacks, LazyLoadSlot, Slot } from 'BaxterScript/types/Slot';
import { Features } from 'BaxterScript/version/web/config/Features';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { getConfigById } from 'BaxterScript/helper/config/Config';

export const id = Features.LAZY_LOAD;

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.lazyLoad;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const loadSlot = async (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  setSlot: (
    source: string,
    pageId: string,
    container: ContainerType,
    params: TargetingParams,
    skipContainerRecentlySetCheck: boolean
  ) => boolean
) => {
  const loadable = setSlot(id, lazyLoadSlot.pageId, container, lazyLoadSlot.params, true);
  if (loadable) {
    if (!lazyLoadSlot[id].config.preRequest && container.state.slot === lazyLoadSlot) {
      ProviderV2.create(container.state.slot as Slot, callbacks);
    }
    await ProviderV2.load(id, [container.state.slot as Slot]);
  }
};

const removeListener = (containerHtmlElement: HTMLElement, slot: LazyLoadSlot) => {
  if (slot[id].state.listener) {
    console.info('[SLOTS][LAZYLOAD][REMOVELISTENER]');
    containerHtmlElement.removeEventListener(slot[id].config.eventName, slot[id].state.listener);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.observer = undefined;
  }
};

const addSlotEventListener = (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): void => {
  console.info('[SLOTS][LAZYLOAD][ADDSLOTEVENTLISTENER]');
  // eslint-disable-next-line no-param-reassign
  lazyLoadSlot[id].state.listener = async (event) => {
    try {
      console.info('[SLOTS][LAZYLOAD][SLOTEVENTLISTENER]', event);
      if (!lazyLoadSlot[id].state.loaded) {
        // eslint-disable-next-line no-param-reassign
        lazyLoadSlot[id].state.loaded = true;
        removeListener(container.state.htmlElement as HTMLElement, lazyLoadSlot);
        await loadSlot(container, lazyLoadSlot, callbacks, setSlot);
      }
    } catch (e) {
      console.error('[SLOTS][LAZYLOAD][SLOTEVENTLISTENER]', e);
      newRelicMetrics.reportError(NewRelicError.LAZY_LOAD_EVENT_LISTENER_ERROR, {
        message: (e as Error).message,
      });
    }
  };
  (container.state.htmlElement as HTMLElement).addEventListener(
    lazyLoadSlot[id].config.eventName,
    lazyLoadSlot[id].state.listener,
    false
  );
};

const removeObserver = (containerHtmlElement: HTMLElement, slot: LazyLoadSlot) => {
  if (slot[id].state.observer) {
    console.info('[SLOTS][LAZYLOAD][REMOVEOBSERVER]');
    slot[id].state.observer.unobserve(containerHtmlElement);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.observer = undefined;
  }
};

const addIntersectionObserver = (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): void => {
  console.info('[SLOTS][LAZYLOAD][ADDINTERSECTIONOBSERVER]');
  const distanceFromViewport = lazyLoadSlot[id].config.distanceFromViewport || 0;
  const rootMargin = `${distanceFromViewport}px 0px ${distanceFromViewport}px 0px`;
  // eslint-disable-next-line no-param-reassign
  lazyLoadSlot[id].state.observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(async (entry) => {
        console.info('[SLOTS][LAZYLOAD][INTERSECTIONOBSERVER]', entry.isIntersecting, entry.intersectionRatio);
        try {
          if (entry.isIntersecting && !lazyLoadSlot[id].state.loaded) {
            // eslint-disable-next-line no-param-reassign
            lazyLoadSlot[id].state.loaded = true;
            removeObserver(container.state.htmlElement as HTMLElement, lazyLoadSlot);
            await loadSlot(container, lazyLoadSlot, callbacks, setSlot);
          }
        } catch (e) {
          console.error('[SLOTS][LAZYLOAD][INTERSECTIONOBSERVER]', e);
          newRelicMetrics.reportError(NewRelicError.LAZY_LOAD_INTERSECTION_OBSERVER_ERROR, {
            message: (e as Error).message,
          });
        }
      });
    },
    {
      rootMargin,
    } as IntersectionObserverInit
  );
  lazyLoadSlot[id].state.observer.observe(container.state.htmlElement as HTMLElement);
};

const applyToSlot = (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): void => {
  console.info('[SLOTS][LAZYLOAD][APPLYTOSLOT]', lazyLoadSlot);
  if (lazyLoadSlot[id].config.preRequest) {
    ProviderV2.create(lazyLoadSlot, callbacks);
  }
  if (lazyLoadSlot[id].config.type === 'event') {
    addSlotEventListener(container, lazyLoadSlot, callbacks, setSlot);
  } else if (lazyLoadSlot[id].config.type === 'visible') {
    addIntersectionObserver(container, lazyLoadSlot, callbacks, setSlot);
  }
};

export const apply = (
  container: ContainerType,
  callbacks: Callbacks,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): boolean => {
  const slot = container.state.slot as Slot;
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config:
      getConfigById(globalThis.Baxter.config?.slots?.settings?.lazyLoad, slot.pageId, slot.containerId, slot.id) || {},
    state: {},
  };
  if (!slot[id].config.enabled) {
    return false;
  }
  if (slot[id].state.alreadyApplied) {
    return false;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.alreadyApplied = true;
  applyToSlot(container, slot as LazyLoadSlot, callbacks, setSlot);
  return true;
};

export const remove = (containerHtmlElement: HTMLElement, slot: Slot): void => {
  if (slot[id]?.config?.enabled) {
    console.info('[SLOTS][LAZYLOAD][REMOVE]', slot);
    if (slot[id].config.type === 'event') {
      removeListener(containerHtmlElement, slot as LazyLoadSlot);
    } else if (slot[id].config.type === 'visible') {
      removeObserver(containerHtmlElement, slot as LazyLoadSlot);
    }
    // eslint-disable-next-line no-param-reassign
    slot[id].state.loaded = false;
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyApplied = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
};
