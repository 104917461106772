import 'intersection-observer';

const handleCustomEvent = () => {
  if (typeof globalThis.CustomEvent !== 'function') {
    const CustomEvent = (type: string, params: CustomEventInit<undefined>) => {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(type, params.bubbles, params.cancelable, params.detail);
      return evt;
    };
    CustomEvent.prototype = window.Event.prototype;
    // @ts-ignore
    globalThis.CustomEvent = CustomEvent;
  }
};

export const bootstrap = () => {
  handleCustomEvent();
};
