import { Cmd, Queue } from 'BaxterScript/helper/queue/Queue';

enum DelayCommands {
  ON_SET = 'ON_SET',
  ON_SET_SPECIFIC_CONTAINERS = 'ON_SET_SPECIFIC_CONTAINERS',
  ON_PAGE_CHANGED = 'ON_PAGE_CHANGED',
  ON_PAGE_PARAMS = 'ON_PAGE_PARAMS',
}
export class LifecycleQueue extends Queue<DelayCommands> {
  delayPageChange(cmd: Cmd, condition: boolean) {
    console.info(`[SLOTS][${this.name}][DELAYPAGECHANGE]`, cmd);
    const isAddedToQueue = this.add({
      key: DelayCommands.ON_PAGE_CHANGED,
      cmd,
      condition,
    });
    if (isAddedToQueue) {
      this.removeAllExceptLast();
    }
    return isAddedToQueue;
  }

  delaySet(cmd: Cmd, condition: boolean) {
    console.info(`[SLOTS][${this.name}][DELAYSET]`, cmd);
    const isAddedToQueue = this.add({
      key: DelayCommands.ON_SET,
      cmd,
      condition,
    });
    if (isAddedToQueue) {
      this.remove(DelayCommands.ON_SET_SPECIFIC_CONTAINERS);
    }
    return isAddedToQueue;
  }

  delaySetSpecificContainers(cmd: Cmd, condition: boolean) {
    console.info(`[SLOTS][${this.name}][DELAYSETSPECIFICCONTAINERS]`, cmd);
    return this.contains(DelayCommands.ON_SET)
      ? true
      : this.add({
          key: DelayCommands.ON_SET_SPECIFIC_CONTAINERS,
          cmd,
          deduplicate: false,
          condition,
        });
  }

  delayPageParams(cmd: Cmd, condition: boolean) {
    console.info(`[SLOTS][${this.name}][DELAYPAGEPARAMS]`, cmd);
    return this.add({
      key: DelayCommands.ON_PAGE_PARAMS,
      cmd,
      condition,
    });
  }
}
