import ConditionCheck from './ConditionCheck';

const testElementForLogicalTree = (element, logicalTree) => {
  if (typeof logicalTree === 'object') {
    const operands = logicalTree.AND ? logicalTree.AND : logicalTree.OR;

    const processedOperands = operands.map((operand) => testElementForLogicalTree(element, operand));

    if (logicalTree.AND) {
      return processedOperands.every((condition) => condition);
    }
    return processedOperands.some((condition) => condition);
  }
  if (typeof logicalTree === 'string') {
    const conditionCheckResult = ConditionCheck(element, logicalTree);
    if (!conditionCheckResult) {
      console.debug(`[SLOTS][TESTELEMENTFORLOGICALTREE] Failed string condition "${logicalTree}"`);
    }
    return conditionCheckResult;
  }
  console.debug(`[SLOTS][TESTELEMENTFORLOGICALTREE] Failed "${logicalTree}"`);
  return false;
};

export default testElementForLogicalTree;
