import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import { clone } from 'BaxterScript/helper/object/Object';
import { query } from 'BaxterScript/helper/query/Condition';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config, ContainerConfig, PlaceholderConfig } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

export const webpackExclude = (config: Config): boolean =>
  !Object.values(config.containers).some((pageContainers) =>
    pageContainers.some((pageContainer) => pageContainer?.placeholders?.map?.length)
  );

const setContainerRedirectUrl = (redirectUrl) => () => {
  try {
    console.info('[SLOTS][PLACEHOLDER][SETCONTAINERREDIRECTURL]');
    window.open(redirectUrl, '_blank');
  } catch (e) {
    console.error('[SLOTS][PLACEHOLDER][SETCONTAINERREDIRECTURL]', e);
    newRelicMetrics.reportError(NewRelicError.PLACEHOLDER_SET_CONTAINER_REDIRECT_URL_ERROR, {
      message: (e as Error).message,
    });
  }
};

const getPlaceholder = (pageId: string, container: ContainerConfig, params: TargetingParams) => {
  let matchedPlaceholder: { id: string; config: PlaceholderConfig } | undefined;
  let defaultPlaceholder: { id: string; config: PlaceholderConfig } | undefined;
  const conditionParams = clone({
    ...State.getUser(),
    params,
  });
  if (container?.placeholders && container?.placeholders.map) {
    for (const placeholder of container.placeholders.map) {
      const { rule } = placeholder;
      if (rule?.condition && query(rule?.condition, conditionParams)) {
        matchedPlaceholder = {
          id: placeholder.defaultDesktop || placeholder.defaultMobile ? 'default' : placeholder.id,
          config: placeholder,
        };
      } else if (!rule?.condition) {
        defaultPlaceholder = {
          id: 'default',
          config: placeholder,
        };
      }
    }
  }
  return matchedPlaceholder || defaultPlaceholder;
};

export const apply = (pageId: string, container: ContainerConfig, params: TargetingParams): boolean => {
  const containerHtmlElement = Html.getElementById(container.id);
  if (!containerHtmlElement) {
    console.debug(`[SLOTS][PLACEHOLDER][APPLYTOPAGE] ${container.id} CONTAINER NOT FOUND`);
    return false;
  }
  const placeholder = getPlaceholder(pageId, container, params);
  if (!placeholder) {
    console.debug('[SLOTS][PLACEHOLDER][APPLY] NO MATCHING PLACEHOLDER FOUND', container, pageId);
    return false;
  }
  console.debug('[SLOTS][PLACEHOLDER][APPLY] APPLYING', pageId, container, placeholder);
  const placeholderBaseClass = `baxter-placeholder--${pageId}--${container.id}`;
  const placeholderClass = `${placeholderBaseClass}--${placeholder.id}`;
  if (placeholder.id === 'default') {
    console.debug(
      '[SLOTS][PLACEHOLDER][APPLY] Set default placeholder',
      containerHtmlElement,
      `${placeholderBaseClass}--default`
    );
    Html.addClass(containerHtmlElement, `${placeholderBaseClass}--default`);
  } else {
    console.debug('[SLOTS][PLACEHOLDER][APPLY] Set custom placeholder', containerHtmlElement, placeholderClass);
    Html.addClass(containerHtmlElement, placeholderClass);
    Html.removeClass(containerHtmlElement, `${placeholderBaseClass}--default`);
  }
  containerHtmlElement.style.backgroundImage = '';
  let eventListener;
  if (placeholder.config.redirectUrl) {
    console.debug('[SLOTS][PLACEHOLDER][APPLY] addEventListener(click)', pageId, container.id);
    eventListener = setContainerRedirectUrl(placeholder.config.redirectUrl);
    containerHtmlElement.addEventListener('click', eventListener);
  }
  State.setPlaceholder(pageId, container.id, { containerHtmlElement, eventListener });
  return true;
};

export const removeFromState = (pageId: string, containerId: string) => {
  const placeholder = State.getPlaceholder(pageId, containerId);
  if (placeholder) {
    if (placeholder.eventListener) {
      placeholder.containerHtmlElement.removeEventListener('click', placeholder.eventListener);
    }
    State.deletePlaceholder(pageId, containerId);
  }
};

export const remove = (pageId: string, containerId: string) => {
  const htmlElement = Html.getElementById(containerId);
  if (htmlElement) {
    htmlElement.classList.forEach((className) => {
      if (className.startsWith('baxter-placeholder')) {
        console.debug('[SLOTS][PLACEHOLDER][REMOVE]', containerId, className);
        Html.removeClass(htmlElement, className);
      }
    });
    htmlElement.style.backgroundImage = 'none';
  }
  removeFromState(pageId, containerId);
};

const applyToPage = (pageId: string, params: TargetingParams = {}) => {
  const containers = globalThis.Baxter.config.containers || {};
  const pageContainers: ContainerConfig[] = containers[pageId] || [];

  const containersWithAppliedPlaceholder = pageContainers
    .filter((container) => apply(pageId, container, params))
    .map((container) => ({
      pageId,
      containerId: container.id,
    }));
  const placeholdersToRemove = State.subtractPlaceholders(containersWithAppliedPlaceholder);
  placeholdersToRemove.forEach((placeholder) => removeFromState(placeholder.pageId, placeholder.containerId));
};

// eslint-disable-next-line import/no-default-export
export default {
  applyToPage,
  apply,
  remove,
};
