import * as Strings from 'BaxterScript/helper/string/String';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { AdSenseSlot, Callbacks, Initialized } from 'BaxterScript/types/Slot';
import { AdSenseConfig } from 'BaxterScript/types/ProviderSlotConfig/AdSense';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as Html from 'BaxterScript/helper/browser/Html';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { AdSenseProviderConfig } from 'BaxterScript/types/ProviderGlobalConfig/AdSenseProviderConfig';

export const id = Providers.AD_SENSE;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const init = () => {
  console.info('[SLOTS][ADSENSE][INIT]');
  // eslint-disable-next-line func-names
  (function (g, o) {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions,no-param-reassign
    (g[o] =
      g[o] ||
      // eslint-disable-next-line func-names
      function () {
        // eslint-disable-next-line no-param-reassign
        (g[o].q = g[o].q || []).push(arguments);
        // eslint-disable-next-line no-sequences
      }),
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      (g[o].t = 1 * new Date());
  })(globalThis, '_googCsa');
};

export const dependencies = () => {
  console.info('[SLOTS][ADSENSE][DEPENDENCIES]');
  return {
    id,
    dependencies: [{ id: 'ads', url: 'https://www.google.com/adsense/search/ads.js' }],
  };
};

export const initialize = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<AdSenseSlot> => {
  console.info('[SLOTS][ADSENSE][INITIALIZE]', pageId, containerId, slotId, params);
  const providerConfig = globalThis.Baxter.config.providers?.[id] as AdSenseProviderConfig;
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdSenseConfig;
  const coreSettings = getConfigById(providerSettings?.core || {}, pageId, containerId, slotId);
  const initialized = {} as AdSenseSlot[typeof id]['initialized'];
  if (providerSettings?.query) {
    const querySettings = getConfigById(providerSettings.query, pageId, containerId, slotId);
    initialized.query = Strings.parseMap(querySettings?.map || [], params);
  }
  if (providerSettings?.channel) {
    const channelSettings = getConfigById(providerSettings.channel, pageId, containerId, slotId);
    initialized.channel = Strings.parseMap(channelSettings?.map || [], params)
      .split('+')
      .map((c) => c.trim())
      .join('+')
      .split(',')
      .map((c) => c.trim())
      .join(',');
  }
  return {
    [id]: {
      providerConfig,
      config: {
        core: coreSettings,
      },
      initialized,
      state: {},
    },
  };
};

export const create = (slot: AdSenseSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][ADSENSE][CREATE]', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].callbacks = callbacks;
};

export const eliminateUtmSources = (referrerRestrictions: AdSenseProviderConfig['referrerRestrictions']): boolean => {
  const url = window?.location?.search;
  const { blocked, allowed } = referrerRestrictions;
  const block = blocked?.map?.some((source) => url?.includes(source)) || false;
  const allow = allowed?.map?.length > 0 ? allowed?.map?.some((source) => !url?.includes(source)) : true;
  return block && allow;
};

export const load = async (source: string, slots: AdSenseSlot[] = []): Promise<void> => {
  console.info('[SLOTS][ADSENSE][LOAD]', slots);
  // eslint-disable-next-line no-underscore-dangle
  const googCsa = globalThis._googCsa;
  if (!googCsa) {
    console.error('[SLOTS][ADSENSE][LOAD] No goog_csa found');
    newRelicMetrics.reportError(NewRelicError.ADSENSE_NO_GOOG_CSA, { command: '[LOAD]' });
    throw new Error('[SLOTS][ADSENSE][LOAD] No goog_csa found');
  }
  if (eliminateUtmSources(slots[0][id].providerConfig.referrerRestrictions || {})) {
    console.debug('[SLOTS][ADSENSE][LOAD] URL contains blocked source', window?.location?.href);
    slots.forEach((slot) => slot[id].callbacks.slotRenderEndedCallback(source, slot, true));
    return;
  }
  slots.forEach((slot) => {
    try {
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][ADSENSE][LOAD] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.ADSENSE_SLOT_ALREADY_REMOVED, { place: 'load' });
        return;
      }
      const pageOptions = {
        pubId: slot[id].providerConfig.settings.accountId,
        styleId: slot[id].config.core?.styleId,
        query: slot[id].initialized.query || '',
        adsafe: slot[id].config.core?.adSafe,
        hl: slot[id].config.core?.hl,
      };
      const external = {
        container: slot.innerId,
        number: undefined,
        adLoadedCallback: (slotInner: string, adsLoaded: boolean): void => {
          try {
            console.debug('[SLOTS][ADSENSE][LOAD][ADLOADEDCALLBACK]', slot, slotInner, adsLoaded);
            if (slot[id].state.alreadyRemoved) {
              console.debug('[SLOTS][ADSENSE][LOAD][ADLOADEDCALLBACK] slot already removed', slot);
              newRelicMetrics.reportMetric(NewRelicMetric.ADSENSE_SLOT_ALREADY_REMOVED, {
                place: 'afterLoadedCallback',
              });
              return;
            }
            if (slot.innerId === slotInner) {
              slot[id].callbacks.slotRenderEndedCallback(source, slot, !adsLoaded);
            }
          } catch (e) {
            console.error('[SLOTS][ADSENSE][LOAD][ADLOADEDCALLBACK]', e);
            newRelicMetrics.reportError(NewRelicError.ADSENSE_CREATE_AD_LOADED_CALLBACK_ERROR, {
              message: (e as Error).message,
            });
            throw e;
          }
        },
      };
      googCsa('ads', pageOptions, external);
    } catch (err) {
      console.error('[SLOTS][ADSENSE][LOAD]', err);
      newRelicMetrics.reportError(NewRelicError.ADSENSE_LOAD_ERROR, { message: (err as Error).message });
    }
  });
};

export const remove = (slots: AdSenseSlot[]) => {
  console.info('[SLOTS][ADSENSE][REMOVE]', slots);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyRemoved = true;
    Html.clear(slot.innerHtmlElement);
  });
};
