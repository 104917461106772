export enum Features {
  NON_LAZY_LOAD = 'nonLazyLoad',
  LAZY_LOAD = 'lazyLoad',
  TIMER_REFRESH = 'timerRefresh',
  BREAKPOINT_REFRESH = 'breakpointRefresh',
  STICKY = 'sticky',
  AUTOPLAY = 'autoplay',
  FALLBACK = 'fallback',
  INTERSTITIAL = 'interstitial',
}
