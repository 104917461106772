const addTargeting = (adTagUrl, targeting) => {
  if (!targeting) {
    return adTagUrl;
  }
  const serializedTargeting = Object.keys(targeting)
    .map((k) => {
      if (Array.isArray(targeting[k])) {
        if (targeting[k].length) {
          return `${k}=${targeting[k].join(',')}`;
        }
        return null;
      }
      if (targeting[k]) {
        return `${k}=${targeting[k]}`;
      }
      return null;
    })
    .filter((v) => v)
    .join('&')
    .replace(/%/g, '%25')
    .replace(/=/g, '%3D')
    .replace(/&/g, '%26')
    .replace(/,/g, '%2C');
  const custParamsIndex = adTagUrl.indexOf('cust_params');
  if (custParamsIndex === -1) {
    return `${adTagUrl}&cust_params=${serializedTargeting}`;
  }
  const ampersandAfterCustParamsIndex = adTagUrl.indexOf('&', custParamsIndex);
  if (ampersandAfterCustParamsIndex === -1) {
    return `${adTagUrl}%26${serializedTargeting}`;
  }
  return `${adTagUrl.substring(0, custParamsIndex)}${adTagUrl.substring(ampersandAfterCustParamsIndex + 1)}&${adTagUrl.substring(custParamsIndex, ampersandAfterCustParamsIndex)}%26${serializedTargeting}`;
};

export { addTargeting };
