import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import {
  videoJSCreateTags,
  videoJSOptions,
  videoJSInitializeAtFirstClick,
  videoJSPlay,
  videoJSPlayer,
  videoJSRemoveControls,
  videoJSScriptDependencies,
  videoJSStyleDependencies,
} from 'BaxterScript/version/web/provider/googleima/VideoJSV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import {
  Callbacks,
  GoogleImaPlayer,
  GoogleImaSlot,
  GoogleImaInitializedPrebid,
  Initialized,
} from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import {
  GoogleImaConfig,
  GoogleImaCoreConfig,
  GoogleImaPrebidConfig,
  GoogleImaTargetingConfig,
} from 'BaxterScript/types/ProviderSlotConfig/GoogleIma';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import PrebidV2 from 'BaxterScript/version/web/provider/googleima/GoogleIMAPrebidV2';
import * as Objects from 'BaxterScript/helper/object/Object';
import {
  GoogleAdsProviderConfig,
  GoogleAdsProviderConfigSettings,
} from 'BaxterScript/types/ProviderGlobalConfig/GoogleAdsProviderConfig';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { initializeTargeting } from '../InitializeTargeting';
import { addTargeting } from './TransformAdTag';

const FAILSAFE_TIMEOUT_DELTA = 500;
export const id = Providers.GOOGLE_IMA;
const GOOGLE_ADS_ID = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const init = () => {
  console.info('[SLOTS][GOOGLEIMA][INIT]');
  if (PrebidV2) {
    PrebidV2.init();
  }
};

export const styleDependencies = () => {
  console.info('[SLOTS][GOOGLEIMA][STYLEDEPENDENCIES]');
  const version = globalThis.Baxter.config.providers[id]?.videoJsIMAVersion;
  const dependencies = videoJSStyleDependencies(globalThis.Baxter.config.cdnDomain, version);
  return {
    id,
    dependencies,
  };
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEIMA][DEPENDENCIES]');
  const version = globalThis.Baxter.config.providers[id]?.videoJsIMAVersion;
  const videoDependencies = videoJSScriptDependencies(globalThis.Baxter.config.cdnDomain, version);
  if (PrebidV2) {
    videoDependencies.push(...PrebidV2.dependencies());
  }
  return {
    id,
    dependencies: videoDependencies,
  };
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEIMA][LOADED]');
  if (PrebidV2) {
    PrebidV2.loaded();
  }
};

export const initialize = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<GoogleImaSlot> => {
  console.info('[SLOTS][GOOGLEIMA][INITIALIZE]', pageId, containerId, slotId, params);
  const slotConfig = (globalThis.Baxter.config.slots?.providerSettings?.[id] || {}) as GoogleImaConfig;
  const providerConfig = globalThis.Baxter.config.providers[GOOGLE_ADS_ID];

  const targeting = (getConfigById(slotConfig.targeting, pageId, containerId, slotId) ||
    {}) as GoogleImaTargetingConfig;
  const initializedTargeting = {
    targeting: {},
  };
  initializeTargeting(initializedTargeting, slotConfig, providerConfig?.settings, pageId, containerId, slotId, params);

  const core = (getConfigById(slotConfig.core, pageId, containerId, slotId) || {}) as GoogleImaCoreConfig;

  const prebidSlotConfig = (getConfigById(slotConfig.prebid, pageId, containerId, slotId) ||
    {}) as GoogleImaPrebidConfig;
  let initializedPrebid = {} as GoogleImaInitializedPrebid;
  if (PrebidV2 && prebidSlotConfig.enabled) {
    initializedPrebid = PrebidV2.initialize(providerConfig, prebidSlotConfig, pageId, containerId, slotId, params);
  }

  const timeout =
    (globalThis.Baxter.config?.containers?.[pageId] || []).find((container) => container.id === containerId)
      ?.fallbackEnabled || false
      ? 1000
      : 20000;

  return {
    [id]: {
      providerConfig,
      config: {
        core,
        targeting,
        prebid: prebidSlotConfig,
      },
      initialized: {
        targeting: initializedTargeting.targeting as TargetingParams,
        prebid: initializedPrebid,
        timeout,
      },
      state: {},
    },
  };
};

const createVideoTag = (slot: GoogleImaSlot, hidden: boolean): void => {
  console.info('[SLOTS][GOOGLEIMA][CREATEVIDEOTAG]', slot);
  if (hidden) {
    Html.hide(slot.innerHtmlElement);
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.innerVideoWrapperHtml = videoJSCreateTags(
    slot.innerHtmlElement,
    slot[id].state.innerVideoWrapperId,
    slot[id].state.innerVideoId,
    slot[id].config.core.posterUrl,
    slot[id].config.core.sources,
    globalThis.Baxter.config.cdnDomain,
    hidden
  );
};

const play = (slot: GoogleImaSlot, player): void => {
  console.info('[SLOTS][GOOGLEIMA][PLAY]', slot);
  if (slot[id].config.core.autoplay && !slot[id].state.autoplayed) {
    console.debug('[SLOTS][GOOGLEIMA][PLAY] autoplay');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_AUTOPLAYED);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.autoplayed = true;
    videoJSPlay(player);
  }
};

const addEvents = (source: string, slot: GoogleImaSlot, player: GoogleImaPlayer, adTagUrl?: string): void => {
  console.info('[SLOTS][GOOGLEIMA][ADDEVENTS]', slot);
  player.on('adsready', () => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK]');
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'adsReady' });
        return;
      }
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_READY);
      if (slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK] do nothing as fallback already applied');
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adsReady = true;
      Html.show(slot.innerHtmlElement);
      Html.show(slot[id].state.innerVideoWrapperHtml);
      slot[id].callbacks.slotRenderEndedCallback(source, slot, false);
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ADSREADYCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  player.on('adserror', (err) => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK]', err);
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'adsError' });
        return;
      }
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_ERROR);
      if (slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] do nothing as fallback already requested');
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adsError = true;
      // eslint-disable-next-line no-param-reassign
      slot[id].state.fallbackApplied = slot[id].callbacks.slotRenderEndedCallback(
        source,
        slot,
        true,
        !!slot[id].config?.core?.sources?.length
      );
      if (slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] fallback');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_ERROR_FALLBACK);
        return;
      }
      if (slot[id].config?.core?.sources?.length) {
        Html.show(slot.innerHtmlElement);
        Html.show(slot[id].state.innerVideoWrapperHtml);
        if (slot[id].state?.shouldAutoplay) {
          console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] play');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_TRY_AUTOPLAY);
          play(slot, player);
        }
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ADSERRORCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  player.on('error', () => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ERRORCALLBACK] play', player?.error());
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'error' });
        return;
      }
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_VIDEO_ERROR, { playerErrorCode: player?.error()?.code });
      if (slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] do nothing as fallback already applied');
        return;
      }
      if (player.error().code === 4) {
        // Error 4 is video didn't load for some reason
        // eslint-disable-next-line no-param-reassign
        slot[id].state.fallbackApplied = slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
        if (slot[id].state.fallbackApplied) {
          console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] fallback');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_VIDEO_ERROR_FALLBACK);
          return;
        }
        console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] reseting player');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_VIDEO_ERROR_RESETING_PLAYER);
        player.dispose();
        createVideoTag(slot, false);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        loadVideo(source, slot, adTagUrl);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ERRORCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ERRORCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  // eslint-disable-next-line no-param-reassign
  slot[id].state.fallbackTimeout = window.setTimeout(() => {
    try {
      console.info('[SLOTS][GOOGLEIMA][TIMEOUT]');
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMA][TIMEOUT] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'fallbackTimeout' });
        return;
      }
      if (slot[id].state.adsReady || slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][TIMEOUT] ads ready or fallback applied', slot);
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.fallbackApplied = slot[id].callbacks.slotRenderEndedCallback(
        source,
        slot,
        true,
        !!slot[id].config?.core?.sources?.length
      );
      if (slot[id].state.fallbackApplied) {
        console.debug('[SLOTS][GOOGLEIMA][TIMEOUT] fallback');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_TIMEOUT_FALLBACK);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][FALLBACKTIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[FALLBACKTIMEOUT]',
        message: (e as Error).message,
      });
    }
  }, slot[id].initialized.timeout + FAILSAFE_TIMEOUT_DELTA);
};

const loadVideo = (source: string, slot: GoogleImaSlot, adTagUrl?: string, retry = 1): void => {
  if (slot[id].state.alreadyRemoved) {
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] slot already removed', slot);
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'loadVideo' });
    return;
  }
  const urls = dependencies().dependencies.map((dependency) => dependency.url);
  if (State.areDependenciesResolved(urls)) {
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved');
    if (!State.areDependenciesResolvedWithSuccess(urls)) {
      console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved with errors');
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_RESOLVED_WITH_ERROR);
      // eslint-disable-next-line no-param-reassign
      slot[id].state.fallbackApplied = slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
      if (slot[id].state.fallbackApplied) {
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_FALLBACK);
      }
      return;
    }
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved with success');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_RESOLVED_WITH_SUCCESS);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.player = videoJSPlayer(slot[id].state.innerVideoId, { muted: !!slot[id].config.core.autoplay });
    addEvents(source, slot, slot[id].state.player, adTagUrl);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.adTagUrl = adTagUrl;
    const imaOptions = videoJSOptions(
      slot[id].state.innerVideoId,
      slot[id].config.core.adLabel,
      slot[id].initialized.timeout
    );
    imaOptions.adsManagerLoadedCallback = () => {
      try {
        console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK]');
        if (slot[id].state.alreadyRemoved) {
          console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, {
            place: 'adManagerLoadedCallback',
          });
          return;
        }
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_MANAGER_LOADED);
        if (slot[id].state.fallbackApplied) {
          console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK] do nothing as fallback already applied');
          return;
        }
        // eslint-disable-next-line no-param-reassign
        slot[id].state.adsManagerLoaded = true;
        if (slot[id].state?.shouldAutoplay) {
          console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK] play');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_TRY_AUTOPLAY);
          play(slot, slot[id].state.player);
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
          command: '[ADSMANAGERLOADEDCALLBACK]',
          message: (e as Error).message,
        });
        throw e;
      }
    };
    if (adTagUrl) {
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adTagUrlWithCustParams = addTargeting(adTagUrl, slot[id].initialized.targeting);
      imaOptions.adTagUrl = slot[id].state.adTagUrlWithCustParams;
    } else {
      imaOptions.adsResponse =
        '<VAST xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="vast.xsd" version="3.0"/>';
    }
    slot[id].state.player.ima(imaOptions);
    videoJSRemoveControls(slot[id].state.innerVideoId);
    videoJSInitializeAtFirstClick(slot[id].state.innerVideoId, slot[id].state.player);
  } else {
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies not yet resolved');
    if (retry <= 20) {
      // eslint-disable-next-line no-param-reassign
      slot[id].state.dependenciesTimeout = window.setTimeout(() => {
        try {
          loadVideo(source, slot, adTagUrl, retry + 1);
        } catch (e) {
          console.error('[SLOTS][GOOGLEIMA][DEPENDENCIESTIMEOUT]', e);
          newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
            command: '[DEPENDENCIESTIMEOUT]',
            message: (e as Error).message,
          });
        }
      }, 100 * retry);
    } else {
      console.error('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies not resolved');
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_DEPENDENCIES_NOT_RESOLVED, {});
      // eslint-disable-next-line no-param-reassign
      slot[id].state.fallbackApplied = slot[id].callbacks.slotRenderEndedCallback(source, slot, true);
      if (slot[id].state.fallbackApplied) {
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_FALLBACK);
      }
    }
  }
};

export const create = (slot: GoogleImaSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][GOOGLEIMA][CREATE]', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].callbacks = callbacks;
  if (slot[id].config.prebid.enabled) {
    PrebidV2.create(slot);
  }
};

export const load = async (source: string, slots: GoogleImaSlot[]): Promise<void> => {
  console.info('[SLOTS][GOOGLEIMA][LOAD]', slots);
  slots.forEach((slot) => {
    try {
      if (slot[id].state.alreadyRemoved) {
        console.debug('[SLOTS][GOOGLEIMA][LOAD] slot already removed', slot);
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'load' });
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.innerVideoWrapperId = `${slot.innerId}-video-wrapper`;
      // eslint-disable-next-line no-param-reassign
      slot[id].state.innerVideoId = `${slot.innerId}-video`;
      createVideoTag(slot, true);
      if (slot[id].config.prebid.enabled) {
        PrebidV2.load(source, slot, loadVideo);
      } else {
        loadVideo(source, slot, slot[id].config.core.adTagUrl);
      }
    } catch (err) {
      console.error('[SLOTS][GOOGLEIMA][LOAD]', err);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_LOAD_ERROR, { message: (err as Error).message });
    }
  });
};

export const autoplay = (slot: GoogleImaSlot) => {
  console.info('[SLOTS][GOOGLEIMA][AUTOPLAY]');
  if (slot[id].state.alreadyRemoved) {
    console.debug('[SLOTS][GOOGLEIMA][AUTOPLAY] slot already removed', slot);
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_SLOT_ALREADY_REMOVED, { place: 'autoplay' });
    return;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.shouldAutoplay = true;
  if (slot[id].state.adsManagerLoaded || slot[id].state.adsError) {
    console.debug('[SLOTS][GOOGLEIMA][AUTOPLAY] play');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_TRY_AUTOPLAY);
    play(slot, slot[id].state.player);
  }
};

export const remove = (slots: GoogleImaSlot[]) => {
  console.info('[SLOTS][GOOGLEIMA][REMOVE]', slots);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyRemoved = true;
    if (PrebidV2) {
      PrebidV2.remove([slot]);
    }
    clearTimeout(slot[id].state.dependenciesTimeout);
    clearTimeout(slot[id].state.fallbackTimeout);
    if (slot[id].state.player) {
      slot[id].state.player.dispose();
    }
    Html.clear(slot.innerHtmlElement);
  });
};

export const setPageTargeting = (params: TargetingParams) => {
  console.info('[SLOTS][GOOGLEIMA][SETPAGETARGETING]');
  const providerSettings = (globalThis.Baxter.config.providers[GOOGLE_ADS_ID] || {}) as GoogleAdsProviderConfig;
  const settings = (providerSettings.settings || {}) as GoogleAdsProviderConfigSettings;
  if (Array.isArray(settings.targeting)) {
    const ranges = globalThis.Baxter.config.app?.ranges;
    const targeting = Objects.parseMap(settings.targeting || [], params, ranges) as TargetingParams;
    if (PrebidV2) {
      PrebidV2.setPageTargeting(targeting);
    }
  }
};
