export const calculateRightLeftBottomTop = (stickyAnchor, closeButtonAnchor, stickyElementBCR, closeButtonBCR) => {
  switch (`${stickyAnchor.value} ${closeButtonAnchor}`) {
    case 'bottom-right top-right':
      return {
        left: stickyElementBCR.right - closeButtonBCR.width,
        bottom: stickyAnchor.bottom + stickyElementBCR.height,
      };
    case 'bottom-right top-left':
      return {
        right: stickyAnchor.right + (stickyElementBCR.width - closeButtonBCR.width),
        bottom: stickyAnchor.bottom + stickyElementBCR.height,
      };
    case 'bottom-right bottom-right':
      return {
        right: stickyAnchor.right,
        bottom: stickyAnchor.bottom - closeButtonBCR.height,
      };
    case 'bottom-right bottom-left':
      return {
        right: stickyAnchor.right + (stickyElementBCR.width - closeButtonBCR.width),
        bottom: stickyAnchor.bottom - closeButtonBCR.height,
      };
    case 'bottom-left top-right':
      return {
        left: stickyAnchor.left + (stickyElementBCR.width - closeButtonBCR.width),
        bottom: stickyAnchor.bottom + stickyElementBCR.height,
      };
    case 'bottom-left top-left':
      return {
        left: stickyAnchor.left,
        bottom: stickyAnchor.bottom + stickyElementBCR.height,
      };
    case 'bottom-left bottom-right':
      return {
        left: stickyAnchor.left + (stickyElementBCR.width - closeButtonBCR.width),
        bottom: stickyAnchor.bottom - closeButtonBCR.height,
      };
    case 'bottom-left bottom-left':
      return {
        left: stickyAnchor.left,
        bottom: stickyAnchor.bottom - closeButtonBCR.height,
      };
    case 'top-right top-right':
      return {
        right: stickyAnchor.right,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-right top-left':
      return {
        right: stickyAnchor.right + (stickyElementBCR.width - closeButtonBCR.width),
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-right bottom-right':
      return {
        right: stickyAnchor.right,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top-right bottom-left':
      return {
        right: stickyAnchor.right + (stickyElementBCR.width - closeButtonBCR.width),
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top-left top-right':
      return {
        left: stickyAnchor.left + (stickyElementBCR.width - closeButtonBCR.width),
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-left top-left':
      return {
        left: stickyAnchor.left,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-left bottom-right':
      return {
        left: stickyAnchor.left + (stickyElementBCR.width - closeButtonBCR.width),
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top-left bottom-left':
      return {
        left: stickyAnchor.left,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top top-right':
      return {
        right: stickyAnchor.right,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top top-left':
      return {
        left: stickyAnchor.left,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top bottom-right':
      return {
        right: stickyAnchor.right,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top bottom-left':
      return {
        left: stickyAnchor.left,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top-no-left-right top-right':
      return {
        left: stickyElementBCR.right - closeButtonBCR.width,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-no-left-right top-left':
      return {
        left: stickyElementBCR.left,
        top: stickyAnchor.top - closeButtonBCR.height,
      };
    case 'top-no-left-right bottom-right':
      return {
        left: stickyElementBCR.right - closeButtonBCR.width,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    case 'top-no-left-right bottom-left':
      return {
        left: stickyElementBCR.left,
        top: stickyAnchor.top + stickyElementBCR.height,
      };
    default:
      throw new Error(
        `[SLOTS][CALCULATERIGHTLEFTBOTTOMTOP] Invalid anchor: ${stickyAnchor.value}, ${closeButtonAnchor}`
      );
  }
};
