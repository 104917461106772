import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { GoogleAdsInitializedBiddersAps, GoogleAdsSlot } from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import {
  GoogleAdsApsConfig,
  GoogleAdsConfig,
  GoogleAdsPrebidConfig,
} from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  const apsSettings = providerSettings.aps;
  return !(
    (apsSettings?._ && Object.values(apsSettings._).some((item) => item?.enabled === true)) ||
    (apsSettings && Object.values(apsSettings).some((item) => item?.enabled === true))
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSAPS][INIT]');
  if (globalThis.apstag) return;
  const q = (c, r) => {
    // eslint-disable-next-line no-underscore-dangle
    globalThis.apstag._Q.push([c, r]);
  };
  globalThis.apstag = {
    init() {
      q('i', arguments);
    },
    fetchBids() {
      q('f', arguments);
    },
    setDisplayBids: () => {},
    targetingKeys: () => [],
    // eslint-disable-next-line no-underscore-dangle
    _Q: [],
  };
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSAPS][DEPENDENCIES]');
  return [
    {
      id: 'aps',
      url: 'https://c.amazon-adsystem.com/aax2/apstag.js',
    },
  ];
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSAPS][LOADED]');
  const apsSettings = globalThis.Baxter.config.providers?.[id]?.aps?.settings;
  if (!apsSettings || !apsSettings.publisherId || !apsSettings.timeout) {
    throw new Error(`[SLOTS][GOOGLEADSAPS][LOADED] missing global aps settings`);
  }
  console.debug('[SLOTS][GOOGLEADSAPS][LOADED]', apsSettings.publisherId, apsSettings.timeout);
  globalThis.apstag.init({
    pubID: apsSettings.publisherId,
    adServer: 'googletag',
    bidTimeout: apsSettings.timeout,
  });
};

export const initialize = (slotConfig: GoogleAdsPrebidConfig | GoogleAdsApsConfig): GoogleAdsInitializedBiddersAps => {
  console.info('[SLOTS][GOOGLEADSAPS][INITIALIZE]', slotConfig);
  return {};
};

export const create = (slot: GoogleAdsSlot, biddersCallback: () => void): void => {
  console.info('[SLOTS][GOOGLEADSAPS][CREATE]', slot);
  biddersCallback();
};

export const load = (apsSlots: GoogleAdsSlot[], biddersCallback: () => void): void => {
  console.info('[SLOTS][GOOGLEADSAPS][LOAD]', apsSlots);
  const { apstag } = globalThis;
  if (apstag) {
    console.debug('[SLOTS][GOOGLEADSAPS][LOAD] requesting bids');
    newRelicMetrics.reportMetric(NewRelicMetric.APS_REQUESTED_BIDS);
    apstag.fetchBids(
      {
        slots: apsSlots.map((apsSlot) => ({
          slotID: apsSlot.innerId,
          slotName: apsSlot[id].initialized.path,
          sizes: apsSlot[id].initialized.sizes?.[0]?.slot,
        })),
      },
      (bids, responseContext) => {
        try {
          if (responseContext?.fromTimeout) {
            console.debug('[SLOTS][GOOGLEADSAPS][LOAD] timed out', bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_TIMED_OUT);
          } else {
            const isArray = Array.isArray(bids);
            const sizes = Array.isArray(bids) ? bids.map((bid) => bid.size).join(',') : 'none';
            console.debug('[SLOTS][GOOGLEADSAPS][LOAD] successfully returned', isArray, sizes, bids);
            newRelicMetrics.reportMetric(NewRelicMetric.APS_SUCCESSFULLY_RETURNED);
          }
          const loadedSlots = apsSlots.filter((slot) => {
            if (slot[id].state.alreadyRemoved) {
              console.debug('[SLOTS][GOOGLEADSAPS][LOAD] slot already removed', slot);
              newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, { place: 'apsCallback' });
              return false;
            }
            return true;
          });
          if (loadedSlots.length) {
            globalThis.googletag.cmd.push(() => {
              try {
                console.debug('[SLOTS][GOOGLEADSAPS][LOAD] apstag.setDisplayBids()', bids);
                apstag.setDisplayBids();
              } catch (e) {
                console.error('[SLOTS][GOOGLEADSAPS][LOAD]', e);
                newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                  command: '[SETDISPLAYBIDS]',
                  message: (e as Error).message,
                });
                throw e;
              }
            });
          }
          biddersCallback();
        } catch (e) {
          console.error('[SLOTS][GOOGLEADSAPS][LOAD]', e);
          newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
            command: '[GOOGLEADSAPSLOAD][BIDSBACKHANDLER]',
            message: (e as Error).message,
          });
          throw e;
        }
      }
    );
  } else {
    console.error(`[SLOTS][GOOGLEADSAPS][LOAD]`);
    newRelicMetrics.reportError(NewRelicError.APS_NO_APS, { command: '[GOOGLEADSAPSLOAD]' });
  }
};

export const remove = (apsSlots: GoogleAdsSlot[]): void => {
  console.info('[SLOTS][GOOGLEADSAPS][REMOVE]', apsSlots);
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADSAPS][SETPAGETARGETING]', params);
};

// eslint-disable-next-line import/no-default-export
export default {
  init,
  dependencies,
  loaded,
  initialize,
  create,
  load,
  remove,
  setPageTargeting,
};
