import * as Objects from 'BaxterScript/helper/object/Object';

export const getConfigById = (config, pageId, containerId, slotId) => {
  if (config == null || typeof config !== 'object') return null;
  const slotKey = `${pageId}#${containerId}#${slotId}`;
  const groupKey = Object.keys(config).find((setting) => setting.split(',').includes(slotKey));
  const settingsDefaults = config?._ || {};
  const settingsDefault = settingsDefaults?._;
  const settingsPage = settingsDefaults[pageId];
  const settingsSlot = config[groupKey!];

  if (
    Objects.isObject(settingsDefault, true) ||
    Objects.isObject(settingsPage, true) ||
    Objects.isObject(settingsSlot, true)
  ) {
    return Objects.merge(settingsDefault || {}, settingsPage || {}, settingsSlot || {});
  }
  if (settingsSlot != null) return settingsSlot;
  if (settingsPage != null) return settingsPage;
  if (settingsDefault != null) return settingsDefault;
};

export const getRuleCondition = (config, pageId, containerId, slotId) =>
  (getConfigById(config?.slots.settings.rule, pageId, containerId, slotId) || {})?.condition;
