import { FallbackSlot, Slot } from 'BaxterScript/types/Slot';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import { Features } from 'BaxterScript/version/web/config/Features';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean =>
  !Object.values(config.containers).some((pageContainers) =>
    pageContainers.some((pageContainer) => pageContainer.fallbackEnabled)
  );

export const id = Features.FALLBACK;

const loadSlot = async (
  container: ContainerType,
  fallbackSlot: FallbackSlot,
  setSlot: (
    source: string,
    pageId: string,
    container: ContainerType,
    params: TargetingParams,
    skipContainerRecentlySetCheck: boolean
  ) => boolean
) => {
  const loadable = setSlot(id, fallbackSlot.pageId, container, fallbackSlot.params, true);
  if (loadable) {
    await ProviderV2.load(id, [container.state.slot as Slot]);
  }
};

export const alreadyApplied = (container: ContainerType, slotId: string): boolean =>
  !!container.state?.[id]?.noFills?.[slotId];

const apply = (
  container: ContainerType,
  slot: Slot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): boolean => {
  if (!container.config.fallbackEnabled) {
    return false;
  }
  if (alreadyApplied(container, slot.id)) {
    return false;
  }
  if (!container.state[id]) {
    // eslint-disable-next-line no-param-reassign
    container.state[id] = {
      noFills: {},
    };
  }
  // eslint-disable-next-line no-param-reassign
  container.state[id].noFills[slot.id] = true;
  loadSlot(container, slot as FallbackSlot, setSlot).catch((err) => {
    console.error('[SLOTS][FALLBACK]', err);
    newRelicMetrics.reportError(NewRelicError.FALLBACK_ERROR, {
      message: (err as Error).message,
    });
  });
  return true;
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  alreadyApplied,
};
