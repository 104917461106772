import * as PrebidV2 from 'BaxterScript/version/web/provider/googleads/bidders/GoogleAdsPrebidV2';
import * as ApsV2 from 'BaxterScript/version/web/provider/googleads/bidders/GoogleAdsApsV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Callbacks, GoogleAdsSlot } from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import { BiddersConfig, BidderV2, SlotsByBidder } from 'BaxterScript/types/Bidders';
import { Bidders } from 'BaxterScript/version/web/config/Bidders';
import { GoogleAdsConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

const FAILSAFE_TIMEOUT = 1100;
const FAILSAFE_TIMEOUT_DELTA = 500;
export const id = Providers.GOOGLE_ADS;

const allBidders: BidderV2[] = [
  { id: Bidders.PREBID, module: PrebidV2 },
  { id: Bidders.APS, module: ApsV2 },
];

const sourceToMetricName = () => ({
  prebid: NewRelicMetric.BIDDERS_PREBID_SENT_AD_SERVER_REQUEST,
  aps: NewRelicMetric.BIDDERS_APS_SENT_AD_SERVER_REQUEST,
  timeout: NewRelicMetric.BIDDERS_TIMEOUT_SENT_AD_SERVER_REQUEST,
});

const enabledSomeBidderForSomeSlot =
  (providerSettings: GoogleAdsConfig) =>
  (bidder: BidderV2): boolean => {
    const bidderSettings = providerSettings?.[bidder.id];
    return !!(
      (bidderSettings?._ && Object.values(bidderSettings._).some((item) => item?.enabled === true)) ||
      (bidderSettings && Object.values(bidderSettings).some((item) => item?.enabled === true))
    );
  };

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  return !allBidders.some(enabledSomeBidderForSomeSlot(providerSettings));
};

const enabledBidders = (): BidderV2[] => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  return allBidders.filter(enabledSomeBidderForSomeSlot(providerSettings));
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][INIT]');
  enabledBidders().forEach((bidder) => bidder.module.init());
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][DEPENDENCIES]');
  return enabledBidders().flatMap((bidder) => bidder.module.dependencies());
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][LOADED]');
  enabledBidders().forEach((bidder) => bidder.module.loaded());
};

const enabledBidderForSlot =
  (biddersConfig: BiddersConfig) =>
  (bidder: BidderV2): boolean =>
    !!biddersConfig[bidder.id]?.enabled;

const enabledBiddersForSlot = (biddersConfig: BiddersConfig): BidderV2[] =>
  allBidders.filter(enabledBidderForSlot(biddersConfig));

export const enabledSomeBidderForSlot = (biddersConfig: BiddersConfig): boolean =>
  allBidders.some(enabledBidderForSlot(biddersConfig));

export const getSlotsByBidder = (slots: GoogleAdsSlot[]): SlotsByBidder => {
  const byBidder: SlotsByBidder = {};
  for (const slot of slots) {
    allBidders.forEach((bidder) => {
      if (enabledBidderForSlot(slot[id].config)(bidder)) {
        if (!byBidder[bidder.id]) {
          byBidder[bidder.id] = [];
        }
        byBidder[bidder.id].push(slot);
      }
    });
  }
  return byBidder;
};

export const initialize = (biddersConfig: BiddersConfig) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][INITIALIZE]');
  let result = {};
  for (const bidder of enabledBiddersForSlot(biddersConfig)) {
    result = {
      ...result,
      [bidder.id]: {
        ...bidder.module.initialize(biddersConfig[bidder.id]),
      },
    };
  }
  return result;
};

export const create = (
  slot: GoogleAdsSlot,
  providerFn: (slot: GoogleAdsSlot, callbacks: Callbacks) => void,
  callbacks: Callbacks
): void => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][CREATE]', slot);
  const slotBidders = enabledBiddersForSlot(slot[id].config);
  const createdSlotBidders = {};
  const callback = (bidderId) => () => {
    console.info('[SLOTS][GOOGLEADSBIDDERS][CREATE][CALLBACK] bidder created', bidderId);
    createdSlotBidders[bidderId] = true;
    if (slotBidders.every((bidder) => createdSlotBidders[bidder.id])) {
      providerFn(slot, callbacks);
    }
  };
  slotBidders.forEach((bidder) => bidder.module.create(slot, callback(bidder.id)));
};

export const load = (slots: GoogleAdsSlot[], googleLoad: (slots: GoogleAdsSlot[]) => void): void => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD]', slots);
  const slotsByBidder = getSlotsByBidder(slots);
  const loadedSlotsBidders = {};
  let adServerRequestSent = false;

  const sendAdServerRequest = (source) => {
    if (adServerRequestSent) {
      console.debug(
        `[SLOTS][GOOGLEADSBIDDERS][LOAD][SENDADSERVERREQUEST] ${source} not sending as request already sent`
      );
      return;
    }
    adServerRequestSent = true;
    console.debug(`[SLOTS][GOOGLEADSBIDDERS][LOAD][SENDADSERVERREQUEST] ${source} sending request`);
    if (sourceToMetricName()[source]) {
      newRelicMetrics.reportMetric(sourceToMetricName()[source]);
    }
    googleLoad(slots);
  };

  const callback = (bidderId) => () => {
    console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD][CALLBACK] bidder loaded', bidderId);
    loadedSlotsBidders[bidderId] = true;
    if (Object.keys(slotsByBidder).every((slotsByBidderBidderId) => loadedSlotsBidders[slotsByBidderBidderId])) {
      sendAdServerRequest(bidderId);
    } else {
      console.debug(`[SLOTS][GOOGLEADSBIDDERS][LOAD][CALLBACK] ${bidderId} not sending as not all bidders in place`);
    }
  };

  let failSafeTimeout = FAILSAFE_TIMEOUT;
  Object.entries(slotsByBidder).forEach(([bidderId, bidderSlots]) => {
    const bidderSettings = globalThis.Baxter.config.providers[id][bidderId]?.settings || {};
    if (bidderSettings.timeout && bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA > failSafeTimeout) {
      failSafeTimeout = bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA;
    }
    allBidders.find((bidder) => bidder.id === bidderId)?.module?.load(bidderSlots, callback(bidderId));
  });
  console.debug('[SLOTS][GOOGLEADSBIDDERS][LOAD] failsafetimeout', failSafeTimeout);
  const timer = setTimeout(() => {
    try {
      console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD][TIMEOUT]');
      sendAdServerRequest('timeout');
    } catch (e) {
      console.error('[SLOTS][GOOGLEADSBIDDERS][LOAD]', e);
      newRelicMetrics.reportError(NewRelicError.BIDDERS_TIMEOUT_ERROR, {
        command: '[GOOGLEADSBIDDERSLOAD]',
        message: (e as Error).message,
      });
    }
  }, failSafeTimeout);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.loadTimer = timer;
  });
};

export const remove = (slots: GoogleAdsSlot[] = []) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][REMOVE]', slots);
  slots.forEach((slot) => {
    clearTimeout(slot[id].state.loadTimer);
  });
  enabledBidders().forEach((bidder) => bidder.module.remove(slots));
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][SETPAGETARGETING]');
  enabledBidders().forEach((bidder) => bidder.module.setPageTargeting(params));
};

// eslint-disable-next-line import/no-default-export
export default {
  enabledSomeBidderForSlot,
  init,
  dependencies,
  loaded,
  initialize,
  create,
  load,
  remove,
  setPageTargeting,
};
