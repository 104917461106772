import * as Strings from 'BaxterScript/helper/string/String';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as Range from 'BaxterScript/helper/array/Range';

export const mapFromVariables = (objectMap: Record<string, string | number>[] = [], variableMap = {}, ranges = {}) => {
  const keyValues: Record<string, unknown> = {};
  const paramsToBlock: string[] = [];
  objectMap.forEach((item) => {
    const key = Strings.removeSpaces(item.key as string);
    const val = Strings.removeSpaces(item.value as string) || key;
    const type = Strings.removeSpaces(item.type as string);

    if (key && type) {
      let mapped: string | null | unknown[] = null;

      if (type === 'param') {
        mapped = formatVal(Objects.get(variableMap, val));
      } else if (type === 'param-keys') {
        const object = val === '*' ? variableMap : Objects.get(variableMap, val);
        setObjectKeyValues(key, object, keyValues);
      } else if (type === 'range' && Range) {
        mapped = formatRange(val, variableMap, ranges);
      } else if (type === 'static') {
        mapped = formatVal(val);
      } else if (type === 'uri-encode') {
        mapped = encodeURIComponent(formatVal(Objects.get(variableMap, val)) as string);
      } else if (type === 'uri-encode-base64') {
        mapped = encodeURIComponent(Strings.base64Encode(formatVal(Objects.get(variableMap, val)) as string));
      } else if (type === 'base64') {
        mapped = Strings.base64Encode(formatVal(Objects.get(variableMap, val)) as string);
      } else if (type === 'block') {
        paramsToBlock.push(key as string);
      }

      if (mapped) keyValues[key] = mapped;
    }
  });

  paramsToBlock.forEach((item) => {
    if (typeof keyValues[item] !== 'undefined') {
      delete keyValues[item];
    }
  });

  return keyValues;
};

const formatRange = (val = '', variableMap = {}, ranges = {}) => {
  const values = val.split(':');
  const rangeKey = values[0];
  if (values.length === 2 && ranges[rangeKey]) {
    return Range.getBetweenString(formatVal(Objects.get(variableMap, values[1])) || '', ranges[rangeKey]);
  }
  return [];
};

const setObjectKeyValues = (key, obj, keyValues) => {
  if (obj && typeof obj === 'object') {
    if (key.includes(':')) {
      setKvsFromObjectValues(key, obj, keyValues);
    } else if (key === '*') {
      setKvsFromObject(key, obj, keyValues);
    } else {
      setKvsFromObjectValue(key, obj, keyValues);
    }
  }
};

const setKvsFromObject = (key, obj, keyValues) => {
  Object.keys(obj).forEach((k) => {
    if (obj.hasOwnProperty(k)) {
      const v = formatVal(obj[k]);
      if (k && v) {
        keyValues[k.trim()] = v;
      }
    }
  });
};
const setKvsFromObjectValue = (key, obj, keyValues) => {
  Object.keys(obj).forEach((k) => {
    if (obj.hasOwnProperty(k)) {
      const v = obj[k][key] ? formatVal(obj[k][key].trim()) : null;
      if (k && v) keyValues[k.trim()] = v;
    }
  });
};
export const setKvsFromObjectValues = (key, obj, keyValues) => {
  const split = key.split(':');
  const k = split[0];
  const v = split[1];
  Object.keys(obj).forEach((i) => {
    if (obj.hasOwnProperty(i)) {
      const item = obj[i];
      const itemKey = item[k];
      const itemVal = formatVal(item[v]);
      if (itemKey && itemVal) {
        keyValues[itemKey.trim()] = itemVal;
      }
    }
  });
};

const formatVal = (val: string) =>
  // eslint-disable-next-line no-nested-ternary
  val == null || (typeof val === 'object' && !Array.isArray(val))
    ? null
    : Array.isArray(val)
      ? val
      : String(val).trim();
