export type Cmd = () => Promise<void>;

export type QueueItem<T> = { key: T; cmd: Cmd };

export class Queue<T> {
  protected queue: QueueItem<T>[];

  protected name: string;

  constructor(name: string) {
    this.queue = [];
    this.name = name;
  }

  async process() {
    console.info(`[SLOTS][${this.name}][QUEUE][PROCESSQUEUE]`, this.queue);
    for (; this.queue.length; ) {
      const next = this.queue.shift();
      if (!next) return;
      console.debug(`[SLOTS][${this.name}][QUEUE][PROCESSQUEUE] Cmd`, next.key, next.cmd);
      // eslint-disable-next-line no-await-in-loop
      await next.cmd.call(undefined);
    }
  }

  add({ key, cmd, condition, deduplicate = true }: { key: T; cmd: Cmd; condition: boolean; deduplicate?: boolean }) {
    console.info(`[SLOTS][${this.name}][QUEUE][ADDTOQUEUE]`, key, cmd, condition, deduplicate);
    if (condition) {
      if (deduplicate) {
        const duplicateCmdIndex = this.queue.findIndex(({ key: currentKey }) => currentKey === key);
        if (duplicateCmdIndex !== -1) {
          console.debug(`[SLOTS][${this.name}][QUEUE][ADDTOQUEUE] command ${key} is already in the queue, replacing`);
          this.queue.splice(duplicateCmdIndex, 1);
        }
      }
      this.queue.push({ key, cmd });
      return true;
    }
    return false;
  }

  contains(key: T) {
    return this.queue.some(({ key: currentKey }) => currentKey === key);
  }

  remove(key: T) {
    console.debug(`[SLOTS][${this.name}][QUEUE][FILTER] ${key}`);
    for (let i = this.queue.length - 1; i >= 0; i--) {
      if (this.queue[i].key === key) {
        console.debug(`[SLOTS][${this.name}][QUEUE][FILTER] Deleting key at ${i}`);
        this.queue.splice(i, 1);
      }
    }
  }

  removeAllExceptLast() {
    if (this.queue.length > 1) {
      this.queue.splice(0, this.queue.length - 1);
    }
  }

  removeAll() {
    this.queue = [];
  }
}
