import * as Condition from 'BaxterScript/helper/query/Condition';
import * as Objects from 'BaxterScript/helper/object/Object';

export const mapFromVariables = (stringMap: Record<string, string>[] = [], variablesMap = {}) => {
  let string = '';
  for (const item of stringMap) {
    const { pattern } = item;
    const condition = item.condition || getDefaultCondition(pattern);

    if (pattern && (!condition || Condition.query(condition, variablesMap))) {
      string = pattern;

      let attempts = 0;
      let matched = 0;
      for (const variable of getVariables(pattern)) {
        attempts++;
        const value = Objects.get(variablesMap, variable);
        if (value != null) {
          const regex = new RegExp(`\\{${variable}\\}`, 'g');
          string = string.replace(regex, String(value));
          matched++;
        }
      }
      if (matched === attempts) break;
    }
  }
  return string.replace(/\{.*?}/g, '');
};

const getVariables = (string) => {
  // todo: move to StringTemplate helper
  const variables = string.split('{').map((variable) => variable.split('}')[0]);
  if (!string.startsWith('{') || variables[0] === '') {
    variables.shift();
  }
  return variables;
};

const getDefaultCondition = (pattern = '') => {
  const variables = getVariables(pattern) || [];
  return `${variables.join(' IS SET AND ')}${variables.length ? ' IS SET' : ''}`;
};
