import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import LogicalTreeBuilder from './LogicalTreeBuilder';
import TestElementForLogicalTree from './TestElementForLogicalTree';

export const query = (queryString, element) => {
  if (!queryString) {
    console.debug('[SLOTS][CONDITION][QUERY] empty query string', queryString, element, true);
    return true;
  }
  try {
    const logicalTree = LogicalTreeBuilder.build(queryString);
    const result = TestElementForLogicalTree(element, logicalTree);
    console.debug('[SLOTS][CONDITION][QUERY] check', queryString, element, result);
    return result;
  } catch (e) {
    console.error('[SLOTS][CONDITION][QUERY]', e);
    newRelicMetrics.reportError(NewRelicError.CONDITION_CHECK_ERROR, {
      queryString,
      element,
      message: (e as Error).message,
    });
    return false;
  }
};

export default {
  query,
};
