import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import * as UUID from 'BaxterScript/helper/string/UUID';
import * as Preview from 'BaxterScript/version/web/core/Preview';
import { InterstitialSlot } from 'BaxterScript/types/Slot';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import { Device } from 'BaxterScript/version/web/config/Device';
import { Config } from 'BaxterScript/types/Config';
import { ContainerType } from 'BaxterScript/types/ContainerType';

export const setProviders = () => {
  const providerConfig = globalThis.Baxter.config.slots?.provider || {};
  const providerState = globalThis.Baxter.state.providers;
  const addToState = (configNode: Config['slots']['provider']) => {
    Object.keys(configNode || {}).forEach((slotKey) => {
      const value = configNode?.[slotKey] || {};
      if (typeof value === 'string') {
        if (!providerState.includes(value)) {
          providerState.push(value);
        }
      } else {
        // @ts-ignore
        addToState(value);
      }
    });
  };

  // @ts-ignore
  addToState(providerConfig);
};

export const setPageId = (pageId: string) => {
  globalThis.Baxter.state.page.id = pageId;
};

export const getPageId = () => globalThis.Baxter.state.page.id;

export const setPageParams = (pageParams: TargetingParams) => {
  globalThis.Baxter.state.page.params = pageParams;
};

export const getPageParams = () => globalThis.Baxter.state.page.params;

export const setInterstitialSlotV2 = (interstitialId: string, slot: InterstitialSlot) => {
  globalThis.Baxter.state.interstitialsV2!.slots[interstitialId] = slot;
};

export const getInterstitialSlotV2 = (interstitialId: string): InterstitialSlot | undefined =>
  globalThis.Baxter.state.interstitialsV2!.slots[interstitialId];

export const removeInterstitialSlotV2 = (interstitialId: string) => {
  delete globalThis.Baxter.state.interstitialsV2!.slots[interstitialId];
};

export const getInterstitialSlotsV2 = (): Record<string, InterstitialSlot> =>
  globalThis.Baxter.state.interstitialsV2!.slots;

export const setUserId = () => {
  globalThis.Baxter.state.user.id = Cookie.get('dfp_user_id') || `${UUID.v4()}-ver2`;
};

export const getUserId = () => globalThis.Baxter.state.user.id;

export const setSessionLong = () => {
  const cookieValue = Cookie.get('onap');
  const [sessionLong] = cookieValue?.split('-') || [];
  globalThis.Baxter.state.user.sessionLong = sessionLong;
};

export const getSessionLong = () => globalThis.Baxter.state.user.sessionLong;

export const setUserActiveV2 = (active) => {
  globalThis.Baxter.state.user.active = active;
};

export const getUserActive = () => globalThis.Baxter.state.user.active;

export const setUserConsentResolved = () => {
  globalThis.Baxter.state.user.consentResolved = true;
};

export const isUserConsentResolved = () => globalThis.Baxter.state.user.consentResolved;

export const setUserConsentGiven = (consent: boolean) => {
  globalThis.Baxter.state.user.consentGiven = consent;
};

export const isUserConsentGiven = () => globalThis.Baxter.state.user.consentGiven;

export const setBreakpoints = () => {
  const settings = globalThis.Baxter.config.app?.breakpoints || {};
  const breakpoints: number[] = [];
  Object.values(settings).forEach((breakpoint) => {
    const numericBreakpoint = Number(breakpoint);
    if (!Number.isNaN(numericBreakpoint)) {
      breakpoints.push(numericBreakpoint);
    }
  });
  breakpoints.sort((a, b) => b - a);
  console.debug('[SLOTS][STATE][SETBREAKPOINTS]', breakpoints);
  globalThis.Baxter.state.app.breakpoints = breakpoints;
};

export const getBreakpoints = () => globalThis.Baxter.state.app.breakpoints;

const setDeviceSize = (breakpoint) => {
  const { breakpoints } = globalThis.Baxter.config.app;
  globalThis.Baxter.state.user.deviceSize = Object.keys(breakpoints).find(
    (device) => breakpoints[device] === breakpoint
  );
};

export const setBreakpointAndDeviceSize = () => {
  const currentBreakpoint = globalThis.Baxter.state.app.breakpoint;
  globalThis.Baxter.state.app.breakpoint = getBreakpoints().find((val) => val <= globalThis.innerWidth);
  if (currentBreakpoint !== globalThis.Baxter.state.app.breakpoint) {
    setDeviceSize(globalThis.Baxter.state.app.breakpoint);
    return true;
  }
  return false;
};

export const setDeviceType = () => {
  const ua = globalThis.navigator.userAgent;
  let deviceType = Device.DESKTOP;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    deviceType = Device.TABLET;
  } else if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    deviceType = Device.MOBILE;
  }
  globalThis.Baxter.state.user.deviceType = deviceType;
};

export const setPreviewToken = () => {
  globalThis.Baxter.state.user.previewToken = Preview.getCookie();
};

export const getPreviewToken = () => globalThis.Baxter.state.user.previewToken;

export const setCxenseSegments = (segments: unknown[]) => {
  globalThis.Baxter.state.user.cxense.segments = segments;
};

export const getCxenseSegments = () => globalThis.Baxter.state.user.cxense.segments;

export const setCxensePageViews = (count = 0) => {
  globalThis.Baxter.state.user.cxense.pageViews = count;
};

export const getCxensePageViews = () => globalThis.Baxter.state.user.cxense.pageViews || 0;

export const setCxenseIntervalId = (id: NodeJS.Timeout) => {
  globalThis.Baxter.state.user.cxense.intervalId = id;
};

export const getCxenseIntervalId = () => globalThis.Baxter.state.user.cxense.intervalId;

export const setAdblockStatus = (status: boolean) => {
  globalThis.Baxter.state.user.adblockStatus = status;
};

export const getAdblockStatus = () => globalThis.Baxter.state.user.adblockStatus;

export const getUser = () => globalThis.Baxter.state.user;

export const setDependencyResolved = (url: string, success: boolean) => {
  console.info('[SLOTS][GOOGLEIMA][SETDEPENDENCYRESOLVED]', url, success);
  globalThis.Baxter.state.resolvedDependencies = globalThis.Baxter.state.resolvedDependencies || [];
  globalThis.Baxter.state.resolvedDependencies.push({ url, success });
};

export const areDependenciesResolved = (urls: string[]) =>
  urls.every((urlToFind) => globalThis.Baxter.state.resolvedDependencies?.find(({ url }) => urlToFind === url));

export const areDependenciesResolvedWithSuccess = (urls: string[]) =>
  urls.every((urlToFind) =>
    globalThis.Baxter.state.resolvedDependencies?.find(({ url, success }) => urlToFind === url && success)
  );

export const getContainers = (): Record<string, ContainerType> => globalThis.Baxter.state.containers;

export const getContainer = (id: string): ContainerType | undefined => globalThis.Baxter.state.containers[id];

export const setContainers = (containers: Record<string, ContainerType>) => {
  globalThis.Baxter.state.containers = containers;
};

const pageContainerKey = (pageId: string, containerId: string) => `${pageId}#${containerId}`;

export const setPlaceholder = (
  pageId: string,
  containerId: string,
  placeholder: { containerHtmlElement: HTMLElement; eventListener?: () => void }
) => {
  globalThis.Baxter.state.placeholders[pageContainerKey(pageId, containerId)] = { ...placeholder, pageId, containerId };
};

export const deletePlaceholder = (pageId: string, containerId: string) => {
  delete globalThis.Baxter.state.placeholders[pageContainerKey(pageId, containerId)];
};

export const getPlaceholder = (pageId: string, containerId: string) =>
  globalThis.Baxter.state.placeholders?.[pageContainerKey(pageId, containerId)];

export const subtractPlaceholders = (
  containers: { pageId: string; containerId: string }[]
): { pageId: string; containerId: string }[] => {
  const containersByKey = containers.reduce((result, container) => {
    // eslint-disable-next-line no-param-reassign
    result[pageContainerKey(container.pageId, container.containerId)] = true;
    return result;
  }, {});
  return (
    Object.entries(globalThis.Baxter.state.placeholders)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => !containersByKey[key])
      .map(([, value]) => ({ pageId: value.pageId, containerId: value.containerId }))
  );
};

export const bootstrap = () => {
  setBreakpoints();
  setBreakpointAndDeviceSize();
  setDeviceType();
  setProviders();
  setPreviewToken();
};
