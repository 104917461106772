import * as Html from 'BaxterScript/helper/browser/Html';
import { Config } from 'BaxterScript/types/Config';
import * as Strings from 'BaxterScript/helper/string/String';

const SCRIPT_ID = 'baxter-script-slots';
const STYLE_ID = 'baxter-link-slots';

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.styling;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item)) ||
    (settings && Object.values(settings).some((item) => !!item))
  );
};

const isRelease = (scriptId) => (Html.getElementById(scriptId) as HTMLScriptElement)?.src.includes('/release/');

const bootstrap = () => {
  const { accountId, appId, cdnDomain } = globalThis.Baxter.config;
  const { versionId } = globalThis.Baxter.config;
  const appPath = `${cdnDomain}/${accountId}/${appId}`;
  if (isRelease(SCRIPT_ID)) {
    Html.addStyleToHead(STYLE_ID, `${appPath}/release/init.css`, true);
  } else {
    Html.addStyleToHead(STYLE_ID, `${appPath}/${versionId}/init.css`, true);
  }
};

const getKeyById = (settings, pageId, containerId, slotId) => {
  if (settings == null) return null;
  const slotKey = `${pageId}#${containerId}#${slotId}`;
  const groupKey = Object.keys(settings).find((setting) => setting.split(',').includes(slotKey));

  const settingsSlot = groupKey ? settings[groupKey] : null;
  if (groupKey && settingsSlot) {
    return Strings.hashCode(groupKey);
  }
  const settingsPage = settings?._[pageId];
  if (settingsPage) {
    return Strings.hashCode(pageId);
  }
  const settingsDefault = settings?._?._;
  if (settingsDefault) {
    return Strings.hashCode('_');
  }
  return null;
};

const getClass = (pageId, containerId, slotId) => {
  const settings = globalThis.Baxter.config.slots?.settings;
  if (settings?.styling) {
    const slotStyleKey = getKeyById(settings.styling, pageId, containerId, slotId);
    if (slotStyleKey) {
      return `baxter-${slotStyleKey}`;
    }
  }
  return null;
};

const addClass = (htmlElement: HTMLElement, pageId: string, containerId: string, slotId: string) => {
  const className = getClass(pageId, containerId, slotId);
  if (className) {
    Html.addClass(htmlElement, className);
  }
};

const removeClass = (htmlElement: HTMLElement, pageId: string, containerId: string, slotId: string) => {
  const className = getClass(pageId, containerId, slotId);
  if (className) {
    Html.removeClass(htmlElement, className);
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  addClass,
  removeClass,
};
