import { Config } from 'BaxterScript/types/Config';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { GoogleImaConfig, GoogleImaCoreConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleIma';
import { Features } from 'BaxterScript/version/web/config/Features';
import { AutoplaySlot, Slot } from 'BaxterScript/types/Slot';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';

export const id = Features.AUTOPLAY;
const GOOGLE_IMA_ID = Providers.GOOGLE_IMA;

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = (config.slots?.providerSettings?.[GOOGLE_IMA_ID] ?? {}) as GoogleImaConfig;
  const coreSettings = providerSettings.core;
  return !(
    (coreSettings?._ && Object.values(coreSettings._).some((item) => item?.autoplay)) ||
    (coreSettings && Object.values(coreSettings).some((item) => item?.autoplay))
  );
};
const removeObservers = (slot: AutoplaySlot) => {
  console.info('[SLOTS][AUTOPLAY][REMOVEOBSERVERS]');
  if (slot[id].state.intersectionObserver) {
    slot[id].state.intersectionObserver.unobserve(slot.innerHtmlElement);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.intersectionObserver = undefined;
  }
  if (slot[id].state.resizeObserver) {
    slot[id].state.resizeObserver.unobserve(slot.innerHtmlElement);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.resizeObserver = undefined;
  }
};

const removeVisibilityChangeListener = (slot: AutoplaySlot) => {
  if (slot[id].state.visibilityChangeListener) {
    document.removeEventListener('visibilitychange', slot[id].state.visibilityChangeListener);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibilityChangeListener = undefined;
  }
};

const autoplay = (autoplaySlot: AutoplaySlot) => {
  ProviderV2.autoplay(autoplaySlot);
};

const setAutoplayVisibilityListener = (autoplaySlot: AutoplaySlot) => {
  if (document.visibilityState === 'visible') {
    autoplay(autoplaySlot);
  } else {
    console.debug('[SLOTS][AUTOPLAY][TRYAUTOPLAY] visibleState != visible');
    newRelicMetrics.reportMetric(NewRelicMetric.AUTOPLAY_ADD_VISIBILITY_CHANGE_LISTENER);
    // eslint-disable-next-line no-param-reassign
    autoplaySlot[id].state.visibilityChangeListener = () => {
      try {
        removeVisibilityChangeListener(autoplaySlot);
        console.debug(`[SLOTS][AUTOPLAY][TRYAUTOPLAY][VISIBILITYCHANGE] ${document.visibilityState}`);
        newRelicMetrics.reportMetric(NewRelicMetric.AUTOPLAY_VISIBILITY_CHANGED, {
          visibility: document.visibilityState,
        });
        autoplay(autoplaySlot);
      } catch (err) {
        console.error('[SLOTS][AUTOPLAY][TRYAUTOPLAY][VISIBILITYCHANGE]', err);
        newRelicMetrics.reportError(NewRelicError.AUTOPLAY_VISIBILITY_CHANGE_ERROR, {
          message: (err as Error).message,
        });
      }
    };
    document.addEventListener('visibilitychange', autoplaySlot[id].state.visibilityChangeListener);
  }
};

const addResizeObserver = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][ADDRESIZEOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  autoplaySlot[id].state.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    entries.forEach(() => {
      console.info('[SLOTS][AUTOPLAY][RESIZEOBSERVER]');
      try {
        if (!autoplaySlot[id].state.autoplayed) {
          // eslint-disable-next-line no-param-reassign
          const boundingRect = autoplaySlot.innerHtmlElement.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
          const top = Math.max(boundingRect.top, 0);
          const bottom = Math.min(boundingRect.bottom, viewportHeight);
          if (boundingRect.height > 0 && bottom - top > boundingRect.height / 2) {
            // eslint-disable-next-line no-param-reassign
            autoplaySlot[id].state.autoplayed = true;
            removeObservers(autoplaySlot);
            console.debug('[SLOTS][AUTOPLAY][RESIZEOBSERVER] Provider.autoplay(...)');
            setAutoplayVisibilityListener(autoplaySlot);
          }
        }
      } catch (e) {
        console.error('[SLOTS][AUTOPLAY][RESIZEOBSERVER]', e);
        newRelicMetrics.reportError(NewRelicError.AUTOPLAY_RESIZE_OBSERVER_ERROR, {
          message: (e as Error).message,
        });
      }
    });
  });
  autoplaySlot[id].state.resizeObserver.observe(autoplaySlot.innerHtmlElement);
};

const addIntersectionObserver = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][ADDINTERSECTIONOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  autoplaySlot[id].state.intersectionObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        console.info('[SLOTS][AUTOPLAY][INTERSECTIONOBSERVER]', entry.isIntersecting, entry.intersectionRatio);
        try {
          if (entry.isIntersecting && !autoplaySlot[id].state.autoplayed) {
            // eslint-disable-next-line no-param-reassign
            autoplaySlot[id].state.autoplayed = true;
            removeObservers(autoplaySlot);
            console.debug('[SLOTS][AUTOPLAY][INTERSECTIONOBSERVER] Provider.autoplay(...)');
            setAutoplayVisibilityListener(autoplaySlot);
          }
        } catch (e) {
          console.error('[SLOTS][AUTOPLAY][INTERSECTIONOBSERVER]', e);
          newRelicMetrics.reportError(NewRelicError.AUTOPLAY_INTERSECTION_OBSERVER_ERROR, {
            message: (e as Error).message,
          });
        }
      });
    },
    {
      threshold: 0.5,
    } as IntersectionObserverInit
  );
  autoplaySlot[id].state.intersectionObserver.observe(autoplaySlot.innerHtmlElement);
};

const applyToSlot = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][APPLYTOSLOT]', autoplaySlot);
  addIntersectionObserver(autoplaySlot);
  addResizeObserver(autoplaySlot);
};

const apply = (slot: Slot): boolean => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[GOOGLE_IMA_ID] || {}) as GoogleImaConfig;
  const core = (getConfigById(providerSettings.core, slot.pageId, slot.containerId, slot.id) ||
    {}) as GoogleImaCoreConfig;
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config: { core },
    state: {},
  };
  if (!slot[id].config.core.autoplay) {
    return false;
  }
  if (slot[id].state.alreadyApplied) {
    return false;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.alreadyApplied = true;
  applyToSlot(slot as AutoplaySlot);
  return true;
};

export const remove = (slot: Slot): void => {
  if (slot[id]?.config?.core?.autoplay) {
    console.info('[SLOTS][AUTOPLAY][REMOVE]', slot);
    removeObservers(slot as AutoplaySlot);
    removeVisibilityChangeListener(slot as AutoplaySlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyApplied = false;
    // eslint-disable-next-line no-param-reassign
    slot[id].state.autoplayed = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
};
