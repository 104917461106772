import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';

export const init = async (queue) => {
  console.info('[SLOTS][QUEUE][INIT]');
  queue.push = (cmd) => {
    if (typeof cmd === 'function') {
      if (queue.length) {
        console.debug('[SLOTS][QUEUE][INIT] PUSH', cmd);
        queue[queue.length] = cmd;
      } else {
        console.debug('[SLOTS][QUEUE][INIT] INSTANT CALL', cmd);
        try {
          Promise.resolve(cmd.call()).catch((e) => {
            console.error('[SLOTS][QUEUE][INIT] INSTANT CALL', e);
            newRelicMetrics.reportError(NewRelicError.SCRIPT_QUEUE_ERROR, { message: (e as Error).message });
          });
        } catch (e) {
          console.error('[SLOTS][QUEUE][INIT] INSTANT CALL', e);
          newRelicMetrics.reportError(NewRelicError.SCRIPT_QUEUE_ERROR, { message: (e as Error).message });
        }
      }
    }
  };

  for (; queue.length; ) {
    const cmd = queue.shift();
    if (typeof cmd === 'function') {
      console.debug('[SLOTS][QUEUE][INIT] DELAYED CALL', cmd);
      try {
        // eslint-disable-next-line no-await-in-loop
        await Promise.resolve(cmd.call());
      } catch (e) {
        console.error('[SLOTS][QUEUE][INIT] DELAYED CALL', e);
        newRelicMetrics.reportError(NewRelicError.SCRIPT_QUEUE_ERROR, { message: (e as Error).message });
      }
    }
  }
};
