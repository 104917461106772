import { Constants } from './Constants';
import { isObject } from '../object/Object';
import * as Html from '../browser/Html';

const checkInclusion = (valueOnProperty, lookupValue, isNegated) => {
  if (valueOnProperty) {
    valueOnProperty = cleanValue(valueOnProperty);
    if (Array.isArray(valueOnProperty) || typeof valueOnProperty === 'string') {
      const isIncluded = (valueOnProperty1, lookupValue1) => valueOnProperty1.includes(lookupValue1.trim());
      const includes = checkComposite(isIncluded, valueOnProperty, lookupValue);

      return isNegated ? !includes : includes;
    }
    return !!isNegated;
  }
  return !!isNegated;
};

const checkContainer = (valueOnProperty, lookupValue) => {
  switch (lookupValue) {
    case 'EXISTS':
      return !!Html.getElementById(valueOnProperty);
    case 'NOT EXISTS':
      return !Html.getElementById(valueOnProperty);
    default:
      return false;
  }
};

const checkExclusion = (valueOnProperty, lookupValue) => checkInclusion(valueOnProperty, lookupValue, true);

const checkEquality = (valueOnProperty, lookupValue) => checkComposite(isEqual, valueOnProperty, lookupValue);

const checkInequality = (valueOnProperty, lookupValue) => !checkEquality(valueOnProperty, lookupValue);

const checkIfGreaterThan = (valueOnProperty, lookupValue) => isNumericMatch(valueOnProperty, lookupValue, Constants.GT);

const checkIfLessThan = (valueOnProperty, lookupValue) => isNumericMatch(valueOnProperty, lookupValue, Constants.LT);

const checkIfGreaterThanOrEqual = (valueOnProperty, lookupValue) =>
  isNumericMatch(valueOnProperty, lookupValue, Constants.GTE);

const checkIfLessThanOrEqual = (valueOnProperty, lookupValue) =>
  isNumericMatch(valueOnProperty, lookupValue, Constants.LTE);

const checkComposite = (callback, valueOnProperty, lookupValue) =>
  !!lookupValue
    .split(',')
    .map((value) => callback(valueOnProperty, value))
    .find((result) => result);

const isEqual = (valueOnProperty, lookupValue) => {
  lookupValue = cleanValue(lookupValue);
  if (lookupValue === 'SET') {
    if (isObject(valueOnProperty)) return !!Object.keys(valueOnProperty).length;
    if (Array.isArray(valueOnProperty)) return !!valueOnProperty.length;
    return !!valueOnProperty;
  }
  return lookupValue === cleanValue(valueOnProperty);
};

const isNumericMatch = (valueOnProperty, lookupValue, operator) => {
  if (isDate(valueOnProperty) || valueOnProperty === Constants.DATE) {
    const mapDate = (value) => (cleanValue(value) === Constants.DATE ? Date.now() : Date.parse(value));
    valueOnProperty = mapDate(valueOnProperty);
    lookupValue = mapDate(lookupValue);
  } else if (isNumeric(lookupValue) && isNumeric(valueOnProperty)) {
    valueOnProperty = Number(valueOnProperty);
    lookupValue = Number(lookupValue);
  } else {
    return false;
  }

  switch (operator) {
    case Constants.GT:
      return valueOnProperty > lookupValue;
    case Constants.GTE:
      return valueOnProperty >= lookupValue;
    case Constants.LT:
      return valueOnProperty < lookupValue;
    case Constants.LTE:
      return valueOnProperty <= lookupValue;
    default:
      return false;
  }
};

const cleanValue = (value) => {
  const clean = (v) => String(v).trim();
  return Array.isArray(value) ? value.map((v) => clean(v)) : clean(value);
};

// eslint-disable-next-line no-restricted-globals
const isNumeric = (value) => !isNaN(value);

const isDate = (date) => !isNumeric(date) && isNumeric(new Date(date));

export default {
  checkInclusion,
  checkExclusion,
  checkEquality,
  checkInequality,
  checkIfGreaterThan,
  checkIfLessThan,
  checkIfGreaterThanOrEqual,
  checkIfLessThanOrEqual,
  checkContainer,
};
