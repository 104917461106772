export const getElementById = (elementId: string): HTMLElement | undefined => {
  const element = document.getElementById(elementId);
  return !element || element.nodeType !== 1 ? undefined : element;
};

export const getScriptUrl = (id) => {
  const container = getElementById(id) as HTMLScriptElement;
  return container ? container.src : '';
};

export const getClasses = (element) => {
  if (element) {
    return Array.from(element.classList.entries()).map((item) => item?.[1] || '');
  }
  return [];
};

export const hasClass = (element, className) => element && className && element.classList.contains(className);

export const addClass = (element, className) => {
  if (element && className && !hasClass(element, className)) {
    console.debug('[SLOTS][HTML][ADDCLASS]', element, className);
    element.classList.add(className);
  }
};

export const removeClass = (element, className) => {
  if (element && className && hasClass(element, className)) {
    console.debug('[SLOTS][HTML][REMOVECLASS]', element, className);
    element.classList.remove(className);
  }
};

export const hide = (element) => {
  console.debug('[SLOTS][HTML][HIDEELEMENT]', element);
  // eslint-disable-next-line no-param-reassign
  element.style.display = 'none';
};

/**
 * @deprecated
 * Use hide instead
 */
export const hideElement = (elementId) => {
  const element = getElementById(elementId);
  if (element) {
    hide(element);
  }
};

export const show = (element) => {
  console.debug('[SLOTS][HTML][SHOWELEMENT]', element);
  // eslint-disable-next-line no-param-reassign
  element.style.display = '';
};

/**
 * @deprecated
 * Use show instead
 */
export const showElement = (elementId) => {
  const element = getElementById(elementId);
  if (element) {
    show(element);
  }
};

export const clear = (element) => {
  console.debug('[SLOTS][HTML][CLEARELEMENT]', element);
  // eslint-disable-next-line no-param-reassign
  element.innerHTML = '';
};

/**
 * @deprecated
 * Use clear instead
 */

export const clearElement = (elementId) => {
  const element = getElementById(elementId);
  if (element) {
    clear(element);
  }
};

export const clearInlineStyles = (element) => {
  console.debug('[SLOTS][HTML][CLEARINLINESTYLES]', element);
  element.removeAttribute('style');
};

/**
 * @deprecated
 * Use clear instead
 */
export const clearElementInlineStyles = (elementId) => {
  const element = getElementById(elementId);
  if (element) {
    console.debug('[SLOTS][HTML][CLEARINLINESTYLES]', element);
    element.removeAttribute('style');
  }
};

export const addStyleToHead = (id, url, override = false) => {
  const styleElement = getElementById(id);
  if (styleElement) {
    if (override) {
      updateHref(styleElement, url);
    } else {
      console.debug('[SLOTS][HTML][ADDSTYLETOHEAD] do not override', url);
      return;
    }
  }
  createStyleElementInHead(id, url);
};

const createStyleElementInHead = (id, url) => {
  const head = document.getElementsByTagName('head')[0];
  console.debug('[SLOTS][HTML][CREATESTYLEELEMENTINHEAD]', url);
  head.appendChild(createStyleElement(id, url));
};

const updateHref = (element, href) => {
  if (element && element.href !== href) {
    console.debug('[SLOTS][HTML][UPDATEHREF]', href);
    element.href = href;
  }
};

export const addScriptToHead = (id, url, attributes = {}) => {
  if (getElementById(id)) {
    console.debug('[SLOTS][HTML][ADDSCRIPTTOHEAD] script already loaded', url);
    return;
  }

  console.debug('[SLOTS][HTML][ADDSCRIPTTOHEAD]', url);
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(createScriptElement(id, url, true, 'text/javascript', attributes));
};

export const updateScriptInHead = (id, url, attributes = {}) => {
  const existing = getElementById(id) as HTMLScriptElement;
  if (existing) {
    console.debug('[SLOTS][HTML][UPDATESCRIPTINHEAD]', url);
    existing.parentNode?.replaceChild(createScriptElement(id, url, true, 'text/javascript', attributes), existing);
  }
};

export const createScriptElementContent = (id, content) => {
  const script = document.createElement('script');

  script.id = id;
  script.type = 'application/javascript';
  script.innerHTML = content;

  return script;
};

export const addScriptContentToHead = (id: string, content: string) => {
  if (!document.getElementById(id)) {
    const head = document.getElementsByTagName('head')[0];
    console.debug('[SLOTS][HTML][ADDSCRIPTCONTENTTOHEAD]', id);
    head.appendChild(createScriptElementContent(id, content));
  }
};

const createScriptElement = (id, url, assync, type, attributes) => {
  const script = document.createElement('script');

  script.id = id;
  script.src = url;
  script.async = assync;
  if (type) {
    script.type = type;
  }

  for (const [key, value] of Object.entries(attributes || {})) {
    script[key] = value;
  }

  return script;
};

const createStyleElement = (id, url) => {
  const link = document.createElement('link');
  link.id = id;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  link.media = 'all';
  return link;
};

export const isElementInViewport = (elementId, distanceFromViewport = 0) => {
  const element = getElementById(elementId);
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  const distance = distanceFromViewport || 0;
  return rect.top - window.innerHeight - distance <= 0 && rect.top + window.innerHeight + distance >= 0;
};

export const addStyleToElement = (element, style, appendPx?: boolean) => {
  Object.keys(style).forEach((key) => {
    console.debug('[SLOTS][HTML][ADDSTYLETOELEMENT]', element, style, appendPx);
    element.style[key] = appendPx ? `${style[key]}px` : style[key];
  });
};
