import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import { Slot, TimerRefreshSlot } from 'BaxterScript/types/Slot';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import { Features } from 'BaxterScript/version/web/config/Features';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import * as State from 'BaxterScript/version/web/core/State';
import { getConfigById } from 'BaxterScript/helper/config/Config';

export const id = Features.TIMER_REFRESH;

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.timerRefresh;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const loadSlots = async (
  containers: ContainerType[],
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  const slotsToLoad = containers
    .map((container) => {
      const slot = container.state.slot as TimerRefreshSlot;
      slot.refreshCount++;
      return {
        container,
        loadable: setSlot(id, slot.pageId, container, slot.params),
      };
    })
    .filter((containerWithLoadable) => containerWithLoadable.loadable)
    .map((containerWithLoadable) => containerWithLoadable.container.state.slot!);
  await ProviderV2.load(id, slotsToLoad);
};

const setUserActive = async (
  value: boolean,
  mouseMoveAndScrollHandler: EventListener,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  document.removeEventListener('mousemove', mouseMoveAndScrollHandler, false);
  document.removeEventListener('scroll', mouseMoveAndScrollHandler, false);
  if (State.getUserActive() !== value) {
    console.debug(`[SLOTS][TIMERREFRESH][SETUSERACTIVE] ${value}`);
    State.setUserActiveV2(value);
    if (value) {
      const containersToLoad = Object.values(State.getContainers())
        .filter(
          (container) =>
            container.state?.slot?.[id]?.state?.visibleAfterTimeout &&
            container.state?.slot?.[id]?.state?.waitingForUserActive
        )
        .map((container) => {
          // eslint-disable-next-line no-param-reassign
          container.state.slot![id].state.waitingForUserActive = false;
          return container;
        });
      await loadSlots(containersToLoad, setSlot);
    }
  }
};

export const bootstrap = (
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  const mouseMoveAndScrollHandler = async () => {
    try {
      await setUserActive(true, mouseMoveAndScrollHandler, setSlot);
    } catch (err) {
      console.error('[SLOTS][TIMERREFRESH][MOUSEMOVEANDSCROLLHANDLER]', err);
      newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_MOUSE_MOVE_AND_SCROLL_ERROR, {
        message: (err as Error).message,
      });
    }
  };
  const visibilityChangeHandler = async () => {
    try {
      await setUserActive(document.visibilityState === 'visible', mouseMoveAndScrollHandler, setSlot);
    } catch (err) {
      console.error('[SLOTS][TIMERREFRESH][VISIBILITYCHANGEHANDLER]', err);
      newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_VISIBILITY_CHANGE_ERROR, {
        message: (err as Error).message,
      });
    }
  };
  document.addEventListener('mousemove', mouseMoveAndScrollHandler, false);
  document.addEventListener('scroll', mouseMoveAndScrollHandler, false);
  document.addEventListener('visibilitychange', visibilityChangeHandler, false);
  setInterval(async () => {
    try {
      await setUserActive(false, mouseMoveAndScrollHandler, setSlot);
      document.addEventListener('mousemove', mouseMoveAndScrollHandler, false);
      document.addEventListener('scroll', mouseMoveAndScrollHandler, false);
    } catch (err) {
      console.error('[SLOTS][TIMERREFRESH][INTERVAL]', err);
      newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_INTERVAL_ERROR, { message: (err as Error).message });
    }
  }, 10 * 1000);
};

const removeVisibleAfterTimeoutObserver = (slot: TimerRefreshSlot) => {
  if (slot[id].state.visibleAfterTimeoutObserver) {
    console.info('[SLOTS][TIMERREFRESH][REMOVEVISIBLEAFTERTIMEOUTOBSERVER]');
    slot[id].state.visibleAfterTimeoutObserver.unobserve(slot.innerHtmlElement);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibleAfterTimeoutObserver = undefined;
  }
};

const addVisibleAfterTimeoutIntersectionObserver = (
  container: ContainerType,
  timerRefreshSlot: TimerRefreshSlot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): void => {
  console.info('[SLOTS][TIMERREFRESH][ADDVISIBLEAFTERTIMEOUTINTERSECTIONOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  timerRefreshSlot[id].state.visibleAfterTimeoutObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(async (entry) => {
        console.info(
          '[SLOTS][TIMERREFRESH][ADDVISIBLEAFTERTIMEOUTINTERSECTIONOBSERVER][OBSERVER]',
          entry.isIntersecting,
          entry.intersectionRatio
        );
        try {
          if (entry.isIntersecting && !timerRefreshSlot[id].state.visibleAfterTimeout) {
            // eslint-disable-next-line no-param-reassign
            timerRefreshSlot[id].state.visibleAfterTimeout = true;
            removeVisibleAfterTimeoutObserver(timerRefreshSlot);
            if (State.getUserActive()) {
              // eslint-disable-next-line no-param-reassign
              timerRefreshSlot[id].state.waitingForUserActive = false;
              await loadSlots([container], setSlot);
            } else {
              // eslint-disable-next-line no-param-reassign
              timerRefreshSlot[id].state.waitingForUserActive = true;
            }
          }
        } catch (e) {
          console.error('[SLOTS][TIMERREFRESH][ADDVISIBLEAFTERTIMEOUTINTERSECTIONOBSERVER][OBSERVER]', e);
          newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_INTERSECTION_OBSERVER_ERROR, {
            message: (e as Error).message,
          });
        }
      });
    },
    {
      threshold: 0.5,
    } as IntersectionObserverInit
  );
  timerRefreshSlot[id].state.visibleAfterTimeoutObserver.observe(timerRefreshSlot.innerHtmlElement);
};

const removeVisibleBeforeTimeoutObserver = (slot: TimerRefreshSlot) => {
  if (slot[id].state.visibleBeforeTimeoutObserver) {
    console.info('[SLOTS][TIMERREFRESH][REMOVEVISIBLEBEFORETIMEOUTOBSERVER]');
    slot[id].state.visibleBeforeTimeoutObserver.unobserve(slot.innerHtmlElement);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibleBeforeTimeoutObserver = undefined;
  }
};

const addVisibleBeforeTimeoutIntersectionObserver = (
  container: ContainerType,
  timerRefreshSlot: TimerRefreshSlot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  console.info('[SLOTS][TIMERREFRESH][ADDVISIBLEBEFORETIMEOUTINTERSECTIONOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  timerRefreshSlot[id].state.visibleBeforeTimeoutObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(async (entry) => {
        try {
          console.info(
            '[SLOTS][TIMERREFRESH][ADDVISIBLEBEFORETIMEOUTINTERSECTIONOBSERVER][OBSERVER]',
            entry.isIntersecting,
            entry.intersectionRatio
          );
          if (entry.isIntersecting && !timerRefreshSlot[id].state.visibleBeforeTimeout) {
            // eslint-disable-next-line no-param-reassign
            timerRefreshSlot[id].state.visibleBeforeTimeout = true;
            removeVisibleBeforeTimeoutObserver(timerRefreshSlot);
            console.debug('[SLOTS][TIMERREFRESH][ADDVISIBLEBEFORETIMEOUTINTERSECTIONOBSERVER][OBSERVER] set timeout');
            // eslint-disable-next-line no-param-reassign
            timerRefreshSlot[id].state.timeout = setTimeout(() => {
              try {
                console.info('[SLOTS][TIMERREFRESH][TIMEOUT]');
                addVisibleAfterTimeoutIntersectionObserver(container, timerRefreshSlot, setSlot);
              } catch (e) {
                console.error('[SLOTS][TIMERREFRESH][TIMEOUT]', e);
                newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_TIMEOUT_ERROR, {
                  message: (e as Error).message,
                });
              }
            }, timerRefreshSlot[id].config.timeout * 1000);
          }
        } catch (e) {
          console.error('[SLOTS][TIMERREFRESH][ADDVISIBLEBEFORETIMEOUTINTERSECTIONOBSERVER][OBSERVER]', e);
          newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_VISIBLE_BEFORE_TIMEOUT_OBSERVER_ERROR, {
            message: (e as Error).message,
          });
        }
      });
    },
    {
      threshold: 0.5,
    } as IntersectionObserverInit
  );
  timerRefreshSlot[id].state.visibleBeforeTimeoutObserver.observe(timerRefreshSlot.innerHtmlElement);
};

export const remove = (slot: Slot): void => {
  if (slot[id]?.config?.enabled) {
    console.info('[SLOTS][TIMERREFRESH][REMOVE]', slot);
    removeVisibleBeforeTimeoutObserver(slot as TimerRefreshSlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibleBeforeTimeout = false;
    clearTimeout(slot[id].state.timeout);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.timeout = undefined;
    removeVisibleAfterTimeoutObserver(slot as TimerRefreshSlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibleAfterTimeout = false;
    // eslint-disable-next-line no-param-reassign
    slot[id].state.waitingForUserActive = false;
  }
};

const applyToSlot = (
  container: ContainerType,
  timerRefreshSlot: TimerRefreshSlot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): void => {
  console.info('[SLOTS][TIMERREFRESH][APPLYTOSLOT]', timerRefreshSlot);
  addVisibleBeforeTimeoutIntersectionObserver(container, timerRefreshSlot, setSlot);
};

const apply = (
  container: ContainerType,
  slot: Slot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): boolean => {
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config:
      getConfigById(globalThis.Baxter.config?.slots?.settings?.timerRefresh, slot.pageId, slot.containerId, slot.id) ||
      {},
    state: {},
  };
  if (!slot[id].config.enabled) {
    return false;
  }
  if (!slot[id].config.timeout || (slot[id].config.timeout && globalThis.isNaN(slot[id].config.timeout))) {
    console.error('[SLOTS][TIMERREFRESH][APPLY] invalid timeout', slot.pageId, slot.containerId, slot.id);
    newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_INVALID_TIMEOUT, {
      containerId: slot.containerId,
      slotId: slot.id,
    });
    return false;
  }
  remove(slot);
  applyToSlot(container, slot as TimerRefreshSlot, setSlot);
  return true;
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  apply,
  remove,
};
