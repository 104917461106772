import * as GoogleAdsV2 from 'BaxterScript/version/web/provider/googleads/GoogleAdsV2';
import * as AdManagerV2 from 'BaxterScript/version/web/provider/admanager/AdManagerV2';
import * as FacebookV2 from 'BaxterScript/version/web/provider/facebook/FacebookV2';
import * as GoogleImaV2 from 'BaxterScript/version/web/provider/googleima/GoogleIMAV2';
import * as AdManagerStaticV2 from 'BaxterScript/version/web/provider/admanager-static/AdManagerStaticV2';
import * as AdSenseV2 from 'BaxterScript/version/web/provider/adsense/AdSenseV2';
import { ProviderV2 } from 'BaxterScript/types/ProviderV2';
import { Providers as ProviderIds } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import { Callbacks, Slot, Initialized } from 'BaxterScript/types/Slot';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';

const Providers: Record<ProviderIds, ProviderV2> = {
  [ProviderIds.GOOGLE_ADS]: GoogleAdsV2,
  [ProviderIds.AD_MANAGER]: AdManagerV2,
  [ProviderIds.FACEBOOK]: FacebookV2,
  [ProviderIds.GOOGLE_IMA]: GoogleImaV2,
  [ProviderIds.AD_MANAGER_STATIC]: AdManagerStaticV2,
  [ProviderIds.AD_SENSE]: AdSenseV2,
} as Record<ProviderIds, ProviderV2>;

const getById = (providerId: ProviderIds): ProviderV2 => {
  const provider = Providers[providerId];
  if (provider) {
    return provider;
  }
  throw new Error(`Missing provider: ${providerId}`);
};

export const styles = (config: Config): string[] =>
  Object.values(Providers)
    .map((provider) => provider.styles?.(config))
    .filter((style) => style) as string[];

const init = (providerModule: ProviderV2) => {
  if (providerModule && typeof providerModule.init === 'function') {
    providerModule.init();
  }
};

const styleDependencies = (providerModule: ProviderV2) => {
  if (providerModule && typeof providerModule.styleDependencies === 'function') {
    const { id: providerId, dependencies: moduleDependencies } = providerModule.styleDependencies();
    moduleDependencies.flat().forEach((dependency) => {
      Html.addStyleToHead(`baxter-style-provider-${providerId}-${dependency.id}`, dependency.url);
    });
  }
};

const dependencies = (providerModule: ProviderV2, alreadyLoadedDependencies: Record<string, boolean>) => {
  if (providerModule && typeof providerModule.dependencies === 'function') {
    const { id: providerId, dependencies: providerDependencies } = providerModule.dependencies();
    for (const dependency of providerDependencies.flat()) {
      const { id: dependencyId, url } = dependency;
      if (!alreadyLoadedDependencies[url]) {
        Html.addScriptToHead(`baxter-script-provider-${providerId}-${dependencyId}`, url, {
          onload: () => State.setDependencyResolved(url, true),
          onerror: () => State.setDependencyResolved(url, false),
        });
        // eslint-disable-next-line no-param-reassign
        alreadyLoadedDependencies[url] = true;
      }
    }
  }
};

const loaded = (providerModule: ProviderV2) => {
  if (providerModule && typeof providerModule.loaded === 'function') {
    providerModule.loaded();
  }
};

export const bootstrap = () => {
  const alreadyLoadedDependencies = {};
  Object.values(Providers).forEach((providerModule) => {
    try {
      init(providerModule);
      styleDependencies(providerModule);
      dependencies(providerModule, alreadyLoadedDependencies);
      loaded(providerModule);
    } catch (e) {
      console.error('[SLOTS][PROVIDER][BOOTSTRAP]', e);
      newRelicMetrics.reportError(NewRelicError.PROVIDER_BOOTSTRAP_ERROR, { message: (e as Error).message });
    }
  });
};

export const consent = (restrictDataProcessing: boolean): void => {
  Object.values(Providers)
    .filter((providerModule) => providerModule?.consent)
    .forEach((providerModule) => {
      try {
        providerModule.consent?.(restrictDataProcessing);
      } catch (err) {
        console.error('[SLOTS][PROVIDER][CONSENT]', err);
        newRelicMetrics.reportError(NewRelicError.PROVIDER_CONSENT_ERROR, { message: (err as Error).message });
      }
    });
};

export const initialize = (
  providerId: ProviderIds,
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<Slot> | undefined => {
  try {
    return getById(providerId).initialize(pageId, containerId, slotId, params);
  } catch (err) {
    console.error('[SLOTS][PROVIDER][INITIALIZE]', err);
    newRelicMetrics.reportError(NewRelicError.PROVIDER_INITIALIZE_ERROR, { message: (err as Error).message });
    return undefined;
  }
};

export const create = (slot: Slot, callbacks: Callbacks): boolean => {
  try {
    getById(slot.provider).create(slot, callbacks);
    return true;
  } catch (err) {
    console.error('[SLOTS][PROVIDER][CREATE]', err);
    newRelicMetrics.reportError(NewRelicError.PROVIDER_CREATE_ERROR, { message: (err as Error).message });
    return false;
  }
};

export const load = async (source: string, slotsToLoad: Slot[]): Promise<void> => {
  const groupedSlots = slotsToLoad.reduce(
    (result, slot) => {
      if (!result[slot.provider]) {
        // eslint-disable-next-line no-param-reassign
        result[slot.provider] = [];
      }
      result[slot.provider].push(slot);
      return result;
    },
    {} as Record<ProviderIds, Slot[]>
  );
  await Promise.all(
    Object.entries(groupedSlots).map(async ([providerId, slots]) => {
      try {
        const providerModule = getById(providerId as ProviderIds);
        await providerModule.load(source, slots);
      } catch (err) {
        console.error('[SLOTS][PROVIDER][LOAD]', err);
        newRelicMetrics.reportError(NewRelicError.PROVIDER_LOAD_ERROR, { message: (err as Error).message });
      }
    })
  );
};

export const autoplay = (slot: Slot) => {
  try {
    const providerModule = getById(slot.provider);
    if (providerModule.autoplay) {
      providerModule.autoplay(slot);
    }
  } catch (err) {
    console.error('[SLOTS][PROVIDER][AUTOPLAY]', err);
    newRelicMetrics.reportError(NewRelicError.PROVIDER_AUTOPLAY_ERROR, { message: (err as Error).message });
  }
};

export const remove = (slotsToRemove: Slot[], clear: boolean): void => {
  const groupedSlots = slotsToRemove.reduce(
    (result, slot) => {
      if (!result[slot.provider]) {
        // eslint-disable-next-line no-param-reassign
        result[slot.provider] = [];
      }
      result[slot.provider].push(slot);
      return result;
    },
    {} as Record<ProviderIds, Slot[]>
  );
  Object.entries(groupedSlots).forEach(([providerId, slots]) => {
    try {
      const providerModule = getById(providerId as ProviderIds);
      providerModule.remove(slots, clear);
    } catch (err) {
      console.error('[SLOTS][PROVIDER][REMOVE]', err);
      newRelicMetrics.reportError(NewRelicError.PROVIDER_REMOVE_ERROR, { message: (err as Error).message });
    }
  });
};

export const setPageTargeting = (params: TargetingParams) => {
  try {
    Object.values(Providers).forEach((providerModule) => {
      providerModule.setPageTargeting?.(params);
    });
  } catch (err) {
    console.error('[SLOTS][PROVIDER][SETPAGETARGETING]', err);
    newRelicMetrics.reportError(NewRelicError.PROVIDER_SET_PAGE_TARGETING_ERROR, { message: (err as Error).message });
  }
};
