export enum Constants {
  AND = 'AND',
  OR = 'OR',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT INCLUDES',
  IS = 'IS',
  IS_NOT = 'IS NOT',
  GT = '>',
  LT = '<',
  GTE = '>=',
  LTE = '<=',
  CONTAINER = 'CONTAINER',

  LEFT_BR = '(',
  RIGHT_BR = ')',
  SUB_EXPRESSION_PREFIX = '#SUB-',

  DATE = 'DATE',
}
