import * as Strings from 'BaxterScript/helper/string/String';

export const getBetweenString = (range = '', intervals: number[] = []) => {
  if (Strings.isString(range)) {
    if (range.includes('-')) {
      const rangeParts = range.split('-');
      const min = Number(rangeParts[0]);
      const max = Number(rangeParts[1]);
      return getBetween(min, max, intervals);
    }
    return getBetween(Number(range), Number(range), intervals);
  }
  return [];
};

export const getBetween = (
  minValue: number | null = null,
  maxValue: number | null = null,
  intervals: number[] = []
) => {
  const intervalsCount = intervals.length;
  const maxInterval = intervals[intervalsCount - 1] || null;
  const rangeValues: (string | number)[] = [];
  for (let i = 0; i < intervalsCount; i++) {
    const rangeStart = intervals[i - 1] || 0;
    const rangeEnd = intervals[i] - 1;
    if ((minValue === null || minValue <= rangeEnd) && (maxValue === null || maxValue >= rangeStart)) {
      rangeValues.push(rangeStart === rangeEnd ? rangeStart : `${rangeStart}-${rangeEnd}`);
    }
  }
  if (minValue === null || (maxInterval !== null && maxValue! > maxInterval)) {
    rangeValues.push(`> ${maxInterval}`);
  }
  return rangeValues;
};
